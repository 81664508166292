import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../hooks/hooks';
import {
  displayGroupModal,
  displayDeleteGroupModal,
} from '../redux/modalsSlice';
import { setEditedGroup } from '../redux/groupEditSlice';
import { Group } from '../types/groups';
import deleteIcon from '../static/icons/bin.svg';

const GroupExisting = ({
  group,
  initiativeId,
}: {
  group: Group;
  initiativeId: string;
}) => {
  const { title, users, id } = group;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onEditGroupClick = () => {
    dispatch(setEditedGroup(group));
    dispatch(displayGroupModal());
  };

  const handleOnStatisticsClick = () => {
    return navigate(`/statistics/group/${id}`);
  };

  const handleOnNotificationClick = () => {
    return navigate(`/notifications/initiatives/${initiativeId}/groups/${id}`);
  };

  const onDeleteGroupClick = async () => {
    dispatch(setEditedGroup(group));
    dispatch(displayDeleteGroupModal());
  };

  return (
    <div className="group">
      <div className="group-avatar">
        <p className="group-avatar-inner">{id}</p>
      </div>
      <p className="title">{title}</p>
      <p className="users">
        {t('team_amount')} <strong>{users.length}</strong>
      </p>
      <button
        className="button-notification"
        onClick={handleOnNotificationClick}
      >
        {t('notifications')}
      </button>
      <button className="button-statistics" onClick={handleOnStatisticsClick}>
        {t('statistics')}
      </button>
      <button
        className="button-edit-group button-violet"
        onClick={onEditGroupClick}
      >
        {t('edit')}
      </button>
      <button
        className={'button-group-edit-remove'}
        onClick={() => onDeleteGroupClick()}
      >
        <img src={deleteIcon} className="remove-icon" alt="remove-icon" />
      </button>
    </div>
  );
};

export default GroupExisting;
