import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { closeGroupModal } from '../redux/modalsSlice';
import GroupCreationUser from './GroupCreationUser';
import GroupCreationSearchedUser from './GroupCreationSearchedUser';
import { CreateGroup } from '../types/groups';
import { Employee } from '../types/employee';
import {
  setEditedGroupTitle,
  setEditedGroupId,
  setEditedGroup,
  saveEditedGroup,
  resetEditedGroup,
  selectEditedGroup,
  selectUsersWithoutGroup,
} from '../redux/groupsCreationSlice';
import { selectEmployees } from '../redux/employeesSlice';
import closeIcon from '../static/icons/close.svg';
import magnifierIcon from '../static/icons/magnifier.svg';
import clearIcon from '../static/icons/clear.svg';

const EditGroupModal = ({ group }: { group: CreateGroup }) => {
  const employees: Employee[] = useAppSelector(selectEmployees);
  const usersWithoutGroup = useAppSelector(selectUsersWithoutGroup);
  const editedGroup = useAppSelector(selectEditedGroup);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setEditedGroup(group));
  }, [dispatch, group]);

  const [filteredSuggestions, setFilteredSuggestions] = useState<Employee[]>(
    []
  );
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<string>('');
  const [searchUser, setSearchUser] = useState<boolean>(false);
  const [filteredUsers, setFilteredUsers] = useState<Employee[]>([]);

  const onAutocompleteChange = (e: any) => {
    const userInput = e.currentTarget.value;

    userInput === '' && setSearchUser(false);

    const filteredSuggestions = employees
      .filter((suggestion: Employee) =>
        `${suggestion.name.toLowerCase()} ${suggestion.surname.toLowerCase()}`.includes(
          userInput.toLowerCase()
        )
      )
      .sort((a: Employee, b: Employee) => (a.name > b.name ? 1 : -1));

    setFilteredSuggestions(filteredSuggestions);
    setShowSuggestions(true);
    setUserInput(e.currentTarget.value);
  };

  const onSugesstionClick = (e: any, suggestion: Employee) => {
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(e.currentTarget.innerText);
    setFilteredUsers([suggestion]);
    setSearchUser(true);
  };

  const onAutocompleteKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      setUserInput(e.target.value);
      setSearchUser(true);

      const filteredUsers = employees
        .filter((suggestion: Employee) =>
          `${suggestion.name.toLowerCase()} ${suggestion.surname.toLowerCase()}`.includes(
            userInput.toLowerCase()
          )
        )
        .sort((a: Employee, b: Employee) => (a.name > b.name ? 1 : -1));

      setFilteredUsers(filteredUsers);
      setShowSuggestions(false);
    }
  };

  const onAutocompleteClick = () => {
    setFilteredSuggestions(employees);
    setShowSuggestions(!showSuggestions);
  };

  const onClearIconClick = () => {
    setUserInput('');
    setSearchUser(false);
  };

  const onCloseModalClick = () => {
    dispatch(setEditedGroupId(0));
    dispatch(resetEditedGroup());
    dispatch(closeGroupModal());
  };

  const onSaveClick = () => {
    dispatch(saveEditedGroup(editedGroup));
    dispatch(setEditedGroupId(0));
    dispatch(resetEditedGroup());
    dispatch(closeGroupModal());
  };

  return (
    <div className="dark-background">
      <div className="centered">
        <div className="modal">
          <div className="modal-header">
            <p className="modal-title">
              {t('team_amount')} {group.title}
            </p>
            <button onClick={onCloseModalClick}>
              <img src={closeIcon} alt="close-icon" className="close-icon" />
            </button>
          </div>
          <div className="create-group-modal-form" key={group.id}>
            <div className="form-group">
              <label>{t('team_name')}</label>
              <input
                type="text"
                className="form-input"
                onChange={(e) => dispatch(setEditedGroupTitle(e.target.value))}
                defaultValue={group.title}
              />
            </div>
            <div className="form-group">
              <label>{t('search_person')}</label>
              <div className="form-group-autocomplete">
                <>
                  <div className="input-search">
                    <img
                      src={magnifierIcon}
                      alt="magnifier-icon"
                      className="magnifier-icon"
                    />
                    <input
                      type="text"
                      className="form-input-autocomplete"
                      onChange={onAutocompleteChange}
                      onKeyDown={onAutocompleteKeyDown}
                      onClick={onAutocompleteClick}
                      value={userInput}
                    />
                    <button
                      className="button-clear-input"
                      onClick={onClearIconClick}
                    >
                      <img
                        src={clearIcon}
                        alt="clear-icon"
                        className={
                          userInput !== '' ? 'clear-icon' : 'clear-icon hidden'
                        }
                      />
                    </button>
                  </div>
                  {showSuggestions && filteredSuggestions.length > 0 && (
                    <ul className="suggestions">
                      {filteredSuggestions.map((suggestion) => {
                        let className;

                        return (
                          <li
                            className={className}
                            key={suggestion.id}
                            onClick={(e) => onSugesstionClick(e, suggestion)}
                          >
                            {suggestion.name || t('no_name')}{' '}
                            {suggestion.surname}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {showSuggestions && userInput && !filteredSuggestions.length && (
                    <ul className="no-suggestions">
                      <li>{t('no_results')}</li>
                    </ul>
                  )}
                </>
              </div>
            </div>
          </div>
          {!searchUser && (
            <>
              <div className="group-users">
                <p className="label">
                  {t('team_added_people')}{' '}
                  <span>{editedGroup.users.length}</span>
                </p>
                <div className="groups-users-list">
                  {editedGroup.users.map((employee: Employee) => (
                    <GroupCreationUser
                      employee={employee}
                      groupId={+group.id!}
                      key={employee.id!}
                    />
                  ))}
                </div>
              </div>
              <div className="group-users">
                <p className="label">
                  {t('team_users_excluded')}{' '}
                  <span>{usersWithoutGroup.length}</span>
                </p>
                <div className="groups-users-list">
                  {usersWithoutGroup.map((employee: Employee) => (
                    <GroupCreationSearchedUser
                      employee={employee}
                      groupId={+group.id!}
                      key={employee.id!}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
          {searchUser && (
            <div className="group-searched-users">
              <p className="label">
                {t('results_found')} <span>{filteredUsers.length}</span>
              </p>
              <div className="groups-searched-users-list">
                {filteredUsers.map((employee: Employee) => (
                  <GroupCreationSearchedUser
                    employee={employee}
                    groupId={+group.id!}
                    key={employee.id!}
                  />
                ))}
              </div>
            </div>
          )}
          <div className="modal-footer">
            <button
              onClick={onCloseModalClick}
              className="button-violet-white button-modal-cancel"
            >
              {t('cancel')}
            </button>
            <button onClick={onSaveClick} className="button-violet">
              {t('confirm')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditGroupModal;
