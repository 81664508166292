import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import { getInitiatives } from '../api/initiatives';
import { Initiative } from '../types/initiatives';
import { Group } from '../types/groups';

export interface InitiativesState {
  data: Initiative[];
  groups: Group[];
  status: 'idle' | 'loading' | 'failed';
}

const serializedInitiatives = localStorage.getItem('initiatives');
const initiativesData = JSON.parse(serializedInitiatives!);

const initialState: InitiativesState = {
  data: initiativesData || [],
  groups: [],
  status: 'idle',
};

export const fetchInitiatives = createAsyncThunk(
  'initiatives/fetchInitiatives',
  async () => {
    const response = await getInitiatives();
    localStorage.setItem('initiatives', JSON.stringify(response));
    return response;
  }
);

export const initiativesSlice = createSlice({
  name: 'initiatives',
  initialState,
  reducers: {
    fetchInitiatives: (state) => {
      state.data = [];
    },
    setInitiativeGroups: (state, action: PayloadAction<Group[]>) => {
      state.groups = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitiatives.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchInitiatives.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload;
      })
      .addCase(fetchInitiatives.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setInitiativeGroups } = initiativesSlice.actions;

export const selectInitiatives = (state: RootState) => state.initiatives.data;
export const selectInitiativeGroups = (state: RootState) =>
  state.initiatives.groups;

export default initiativesSlice.reducer;
