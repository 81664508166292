import React, {
  useState,
  useEffect,
  useRef,
  MouseEvent,
  DragEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import InitialFlowStepper from './InitialFlowStepper';
import InitialFlowFooter from './InitialFlowFooter';
import MobileAppPreviewModal from './MobileAppPreviewModal';
import { uploadPhoto } from '../api/photos';
import { useAppSelector, useAppDispatch } from '../hooks/hooks';
import {
  selectInitialFlowIsActive,
  setCurrentStep,
} from '../redux/initialFlowSlice';
import {
  setTitle,
  setDescription,
  setGoal,
  setPhotoUrl,
  setDateFrom,
  setDateTo,
  selectNewInitiativeTitle,
  selectNewInitiativeDescription,
  selectNewInitiativeGoal,
  selectNewInitiativePhotoUrl,
  selectNewInitiativeDateFrom,
  selectNewInitiativeDateTo,
} from '../redux/initiativeCreationSlice';
import {
  selectDisplayMobileAppPreviewModal,
  displayMobileAppPreviewModal,
} from '../redux/modalsSlice';
import uploadPhotoImage from '../static/images/upload-photo.svg';
import deletePhotoIcon from '../static/icons/bin-white.svg';
import cameraIcon from '../static/icons/camera.svg';
import createInitiativeIcon from '../static/icons/create-initiative.svg';
import editIcon from '../static/icons/edit-gray.svg';
import DatePicker from './DatePicker';
import { formatDate, formatISODate } from '../helpers/dates';

const CreateNewInitiativeScreen = () => {
  const initialFlow = useAppSelector(selectInitialFlowIsActive);
  const showModal = useAppSelector(selectDisplayMobileAppPreviewModal);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentStep(2));
  }, [dispatch]);

  const [file, setFile] = useState<any>(null);
  const [uploadError, setUploadError] = useState<string>('');

  const title = useAppSelector(selectNewInitiativeTitle);
  const description = useAppSelector(selectNewInitiativeDescription);
  const goal = useAppSelector(selectNewInitiativeGoal);
  const photo = useAppSelector(selectNewInitiativePhotoUrl);
  const dateFrom = useAppSelector(selectNewInitiativeDateFrom);
  const dateTo = useAppSelector(selectNewInitiativeDateTo);

  const [titleError, setTitleError] = useState<boolean>(false);
  const [descriptionError, setDescriptionError] = useState<boolean>(false);
  const [goalError, setGoalError] = useState<boolean>(false);
  const [titleToShortError, setTitleToShortError] = useState<boolean>(false);
  const [descriptionToShortError, setDescriptionToShortError] =
    useState<boolean>(false);
  const [goalToShortError, setGoalToShortError] = useState<boolean>(false);

  const [dateFromInitiative, setDateFromInitiative] = useState<string>(
    dateFrom || `${formatDate(new Date())}`
  );

  const [dateToInitiative, setDateToInitiative] = useState<string>(
    dateTo || `${formatDate(new Date())}`
  );

  const errorWrongFormat = t('error_wrong_format');
  const errorTooLargeFile = t('error_too_large_file');
  const errorWrongDimensions = t('error_wrong_dimensions');

  const onTitleChange = (value: string) => {
    dispatch(setTitle(value));
    if (titleError || titleToShortError) {
      value.length < 255 ? setTitleError(false) : setTitleError(true);
      value.length > 5
        ? setTitleToShortError(false)
        : setTitleToShortError(true);
    }
  };

  const onTitleBlur = (value: string) => {
    value.length < 255 ? setTitleError(false) : setTitleError(true);
    value.length > 5 ? setTitleToShortError(false) : setTitleToShortError(true);
  };

  const onDescriptionChange = (value: string) => {
    dispatch(setDescription(value));
    if (descriptionError || descriptionToShortError) {
      value.length < 2000
        ? setDescriptionError(false)
        : setDescriptionError(true);
      value.length > 5
        ? setDescriptionToShortError(false)
        : setDescriptionToShortError(true);
    }
  };

  const onDescriptionBlur = (value: string) => {
    value.length < 2000
      ? setDescriptionError(false)
      : setDescriptionError(true);
    value.length > 5
      ? setDescriptionToShortError(false)
      : setDescriptionToShortError(true);
  };

  const onGoalChange = (value: string) => {
    dispatch(setGoal(value));
    if (goalError || goalToShortError) {
      value.length < 500 ? setGoalError(false) : setGoalError(true);
      value.length > 5 ? setGoalToShortError(false) : setGoalToShortError(true);
    }
  };

  const onGoalBlur = (value: string) => {
    value.length < 500 ? setGoalError(false) : setGoalError(true);
    value.length > 5 ? setGoalToShortError(false) : setGoalToShortError(true);
  };

  const [dragActive, setDragActive] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    setFile(e.dataTransfer.files[0]);
    e.dataTransfer.files[0] && handleOnSubmitFile(e.dataTransfer.files[0]);
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    e.target.files[0] && handleOnSubmitFile(e.target.files[0]);
  };

  const onUploadImageButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    inputRef.current!.click();
  };

  const onDeleteImageButtonClick = () => {
    dispatch(setPhotoUrl(''));
    setFile(null);
  };

  const onShowPreviewModalButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(displayMobileAppPreviewModal());
  };

  const handleOnSubmitFile = async (file: any) => {
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      return setUploadError(errorWrongFormat);
    } else if (file.size > 1000000) {
      return setUploadError(errorTooLargeFile);
    }
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.naturalWidth !== 750 || img.naturalHeight !== 362) {
        return setUploadError(errorWrongDimensions);
      }
    };
    const formData: FormData = new FormData();
    formData.append('photo', file);
    const { path } = await uploadPhoto(formData);
    path && dispatch(setPhotoUrl(path));
  };

  const handleDateFromOnChange = (date: Date) => {
    const dateFrom = formatISODate(`${date}`);
    setDateFromInitiative(dateFrom);
    dispatch(setDateFrom(date));
  };

  const handleDateToOnChange = (date: Date) => {
    const dateTo = formatISODate(`${date}`);
    setDateToInitiative(dateTo);
    dispatch(setDateTo(date));
  };

  useEffect(() => {
    const comparison = dateFromInitiative > dateToInitiative;
    if (comparison) {
      setDateToInitiative(dateFromInitiative);
    }
  }, [dateFromInitiative, dateToInitiative]);

  useEffect(() => {
    const comparison = dateFromInitiative > dateToInitiative;
    if (comparison) {
      setDateFromInitiative(dateToInitiative);
    }
  }, [dateFromInitiative, dateToInitiative]);

  return (
    <>
      {initialFlow && <InitialFlowStepper />}
      <div className="section">
        <div className="container">
          <p className="section-title">{t('initiative_create')}</p>
          <p className="create-initative-form-label">
            {t('add_initiative_label')}
          </p>
        </div>
      </div>
      <div className="section section-white">
        <div className="container section-white">
          <div className="initiative-title-with-icon">
            <img src={createInitiativeIcon} alt="create-initiative-icon" />
            <p className="title">{t('add_description')}</p>
          </div>
          <div className="create-initative-form">
            <form>
              <div className="create-initative-form-column">
                <div className="form-group">
                  <label
                    className={
                      titleError || titleToShortError ? 'label-error' : ''
                    }
                  >
                    {t('initiative_title')}
                    {titleError &&
                      ` (${t('error_long_title').toLocaleLowerCase()})`}
                    {titleToShortError &&
                      ` (${t('error_short_title').toLocaleLowerCase()})`}
                  </label>
                  <input
                    type="text"
                    className={
                      titleError || titleToShortError
                        ? 'form-input input-error'
                        : 'form-input'
                    }
                    onChange={(e) => onTitleChange(e.target.value)}
                    onBlur={(e) => onTitleBlur(e.target.value)}
                    value={title}
                  />
                  <img
                    src={editIcon}
                    alt="edit-icon"
                    className="input-edit-icon"
                  />
                </div>
                <div className="form-group">
                  <label
                    className={
                      descriptionError || descriptionToShortError
                        ? 'label-error'
                        : ''
                    }
                  >
                    {t('initiative_description')}
                    {descriptionError &&
                      ` (${t('error_long_description').toLocaleLowerCase()})`}
                    {descriptionToShortError &&
                      ` (${t('error_short_description').toLocaleLowerCase()})`}
                  </label>
                  <textarea
                    className={
                      descriptionError || descriptionToShortError
                        ? 'form-textarea-description input-error'
                        : 'form-textarea-description'
                    }
                    onChange={(e) => onDescriptionChange(e.target.value)}
                    onBlur={(e) => onDescriptionBlur(e.target.value)}
                    value={description}
                  />
                  <img
                    src={editIcon}
                    alt="edit-icon"
                    className="input-edit-icon"
                  />
                </div>
                <div className="form-group">
                  <label
                    className={
                      goalError || goalToShortError ? 'label-error' : ''
                    }
                  >
                    {t('initiative_goal')}
                    {goalError &&
                      ` (${t('error_long_goal').toLocaleLowerCase()})`}
                    {goalToShortError &&
                      ` (${t('error_short_goal').toLocaleLowerCase()})`}
                  </label>
                  <textarea
                    className={
                      goalError || goalToShortError
                        ? 'form-textarea-goal input-error'
                        : 'form-textarea-goal'
                    }
                    onChange={(e) => onGoalChange(e.target.value)}
                    onBlur={(e) => onGoalBlur(e.target.value)}
                    value={goal}
                  />
                  <img
                    src={editIcon}
                    alt="edit-icon"
                    className="input-edit-icon"
                  />
                </div>
              </div>
              <div className="create-initative-form-column">
                <div className="form-group-row">
                  <div className="form-group">
                    <label>{t('pick_range')}</label>
                    <div className="form-select-range">
                      <DatePicker
                        className="form-input"
                        selected={new Date(dateFromInitiative)}
                        value={new Date(dateFromInitiative)}
                        onChange={handleDateFromOnChange}
                      />
                      <DatePicker
                        className="form-input"
                        selected={new Date(dateToInitiative)}
                        value={new Date(dateToInitiative)}
                        onChange={handleDateToOnChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>{t('image_main')}</label>
                  <div className="add-photo-form">
                    <div className="add-photo-form-column">
                      {!photo && (
                        <div className="form-file-upload">
                          <div
                            onDragEnter={handleDrag}
                            onSubmit={(e) => e.preventDefault()}
                          >
                            <input
                              ref={inputRef}
                              type="file"
                              className="input-file-upload"
                              multiple={true}
                              onChange={handleChange}
                              accept={'image/png, image/jpg'}
                            />
                            <label
                              className={
                                dragActive
                                  ? 'drag-active label-file-upload'
                                  : 'label-file-upload'
                              }
                            >
                              <img
                                src={uploadPhotoImage}
                                className="upload-image"
                                alt="upload-icon"
                              />
                              <p>{uploadError || (file && file.name)}</p>
                            </label>
                            {dragActive && (
                              <div
                                className="drag-file-element"
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                              ></div>
                            )}
                          </div>
                        </div>
                      )}
                      {photo && (
                        <>
                          <img
                            src={photo}
                            alt="initiative"
                            className="initiative-photo"
                          />
                          <button
                            className="button-delete-photo"
                            onClick={onDeleteImageButtonClick}
                          >
                            <img
                              src={deletePhotoIcon}
                              className="delete-photo-icon"
                              alt="delete-icon"
                            />
                          </button>
                          <input
                            ref={inputRef}
                            type="file"
                            className="hidden"
                            multiple={true}
                            onChange={handleChange}
                            accept={'image/png, image/jpg'}
                          />
                        </>
                      )}
                    </div>
                    <div className="add-photo-form-column">
                      <p className="description">{t('image_requirements')}</p>
                      <button
                        className="button-upload-photo"
                        onClick={onUploadImageButtonClick}
                      >
                        <img
                          src={cameraIcon}
                          className="camera-icon"
                          alt="camera-icon"
                        />
                        {photo ? t('image_change') : t('image_pick')}
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  className="button-white-green button-preview-modal"
                  onClick={onShowPreviewModalButtonClick}
                >
                  {t('image_preview')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showModal && <MobileAppPreviewModal />}
      {initialFlow && (
        <InitialFlowFooter
          forwardPath={'/initiatives/create-new/groups/add'}
          backPath={'/employees/add'}
        />
      )}
    </>
  );
};

export default CreateNewInitiativeScreen;
