import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../hooks/hooks';
import { removeExistingUser } from '../redux/groupEditSlice';
import { Employee } from '../types/employee';
import removeIcon from '../static/icons/remove.svg';
import userGroupIcon from '../static/icons/user-group.svg';

const GroupUser = ({
  employee,
  groupId,
}: {
  employee: Employee;
  groupId: number;
}) => {
  const { name, surname, email } = employee;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleOnRemoveUserClick = () => {
    dispatch(removeExistingUser({ id: groupId, user: employee }));
  };

  return (
    <div className="group-user">
      <div className="icon-name">
        <img src={userGroupIcon} alt="user-icon" className="user-icon" />
        <p className="name">
          {name} {surname}
        </p>
      </div>
      <p className="email">{email}</p>
      <button
        className="button-group-user-remove"
        onClick={handleOnRemoveUserClick}
      >
        {t('remove_from_team')}
        <img src={removeIcon} className="remove-icon" alt="remove-icon" />
      </button>
    </div>
  );
};

export default GroupUser;
