import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InitialFlowStepper from './InitialFlowStepper';
import InitialFlowFooter from './InitialFlowFooter';
import EditGroupCreationModal from './EditGroupCreationModal';
import DeleteGroupCreationModal from './DeleteGroupCreationModal';
import GroupCreation from './GroupCreation';
import { CreateGroup } from '../types/groups';
import { useAppSelector, useAppDispatch } from '../hooks/hooks';
import {
  selectGroups,
  selectEditedGroupId,
  addGroup,
} from '../redux/groupsCreationSlice';
import {
  selectDisplayGroupModal,
  selectDisplayDeleteGroupModal,
} from '../redux/modalsSlice';
import {
  selectInitialFlowIsActive,
  setCurrentStep,
} from '../redux/initialFlowSlice';

const AddManualGroupsScreen = () => {
  const navigate = useNavigate();
  const initialFlow = useAppSelector(selectInitialFlowIsActive);

  const groupsToAdd: CreateGroup[] = useAppSelector(selectGroups);
  const showEditGroupModal = useAppSelector(selectDisplayGroupModal);
  const showDeleteGroupModal = useAppSelector(selectDisplayDeleteGroupModal);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setCurrentStep(3));
  }, [dispatch]);

  const editedGroupId: number = useAppSelector(selectEditedGroupId);

  const handleOnAddGroupClick = () => {
    dispatch(addGroup({ id: groupsToAdd.length + 1 }));
  };

  const handleBack = () => {
    navigate('/initiatives/create-new/groups/add');
  };

  return (
    <>
      {initialFlow && <InitialFlowStepper />}
      <div className="section">
        <div className="container">
          <span onClick={handleBack} style={{ cursor: 'pointer' }}>
            {t('back')}
          </span>
          <p className="section-title">{t('creation_manual')}</p>
          <div>
            {groupsToAdd && (
              <>
                {groupsToAdd.map((groupToAdd) => {
                  return (
                    <div key={groupToAdd.id}>
                      <GroupCreation group={groupToAdd} />
                    </div>
                  );
                })}
                <button
                  className="button-add-group button-violet"
                  onClick={handleOnAddGroupClick}
                >
                  {t('add_team')}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {showEditGroupModal && (
        <EditGroupCreationModal group={groupsToAdd[editedGroupId - 1]} />
      )}
      {showDeleteGroupModal && <DeleteGroupCreationModal />}
      {initialFlow && (
        <InitialFlowFooter
          forwardPath={'/initiatives/create-new/confirm'}
          backPath={'/initiatives/create-new/groups/add'}
        />
      )}
    </>
  );
};

export default AddManualGroupsScreen;
