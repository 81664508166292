import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '../static/icons/error.svg';

const ErrorScreen = () => {
  const { t } = useTranslation();
  return (
    <div className="error-screen">
      <div className="error-div">
        <img src={ErrorIcon} alt="error-icon" className="error-icon" />
        <div className="error-div-text">
          <p className="title">{t('error')}</p>
          <p className="label">{t('error_apology')}</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorScreen;
