import React from 'react';
import { useAppDispatch } from '../hooks/hooks';
import { displayDeleteEmployeeModal } from '../redux/modalsSlice';
import { setDeletedEmployeeId } from '../redux/employeesSlice';

import { Employee } from '../types/employee';
import removeIcon from '../static/icons/close.svg';

const EmployeeComponent = ({ employee }: { employee: Employee }) => {
  const { name, surname, email, id } = employee;

  const dispatch = useAppDispatch();

  const onRemoveEmployeeClick = async () => {
    dispatch(setDeletedEmployeeId(id!));
    dispatch(displayDeleteEmployeeModal());
  };

  return (
    <>
      <div className="employee">
        <div className="employee-avatar">
          <p className="employee-avatar-inner">
            {name.substring(0, 1).toUpperCase()}
            {surname.substring(0, 1).toUpperCase()}
          </p>
        </div>
        <p className="name">
          {name} {surname}
        </p>
        <p className="email">{email}</p>
        <button
          className="button-employee-remove"
          onClick={onRemoveEmployeeClick}
        >
          <img src={removeIcon} className="remove-icon" alt="remove-icon" />
        </button>
      </div>
    </>
  );
};

export default EmployeeComponent;
