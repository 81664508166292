import React from 'react';
import { useAppSelector } from '../hooks/hooks';
import { selectSuccess } from '../redux/successSlice';
import { selectError } from '../redux/errorSlice';

const SuccessComponent = () => {
  const success = useAppSelector(selectSuccess);
  const error = useAppSelector(selectError);
  return success && !error ? <div className="success">{success}</div> : null;
};

export default SuccessComponent;
