import React, { useState, useEffect, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hooks/hooks';
import { fetchInitiatives, selectInitiatives } from '../redux/initiativesSlice';
import { setSuccess, resetSuccess } from '../redux/successSlice';
import { getInitiativeGroups } from '../api/groups';
import {
  createNotificationForUser,
  createNotificationForGroup,
  createNotificationForInitiative,
} from '../api/notifications';
import { getEmployees } from '../api/employees';
import { NotificationData } from '../types/notification';
import { Group } from '../types/groups';
import { Employee } from '../types/employee';
import { Initiative } from '../types/initiatives';
import notificationsIcon from '../static/icons/notifications.svg';
import arrowIcon from '../static/icons/select-arrow.svg';

const NotificationsForUserScreen = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [initiativeId, setInitiativeId] = useState<string>(
    params.initiativeId!
  );
  const [groupId, setGroupId] = useState<string>(params.groupId!);
  const [userId, setUserId] = useState<string>(params.userId!);

  const [titleError, setTitleError] = useState<boolean>(false);
  const [contentError, setContentError] = useState<boolean>(false);
  const [initiativeError, setInitiativeError] = useState<boolean>(false);
  const [groupError, setGroupError] = useState<boolean>(false);
  const [userError, setUserError] = useState<boolean>(false);

  const onTitleChange = (value: string) => {
    setTitle(value);
    value.length >= 2 ? setTitleError(false) : setTitleError(true);
  };

  const onContentChange = (value: string) => {
    setContent(value);
    value.length >= 5 ? setContentError(false) : setContentError(true);
  };

  const onInitiativeChange = (value: string) => {
    setInitiativeId(value);
    if (value) {
      setInitiativeError(false);
      setGroupError(false);
      setUserError(false);
    } else if (!initiativeId && groupId) {
      setInitiativeError(true);
    }
  };

  const onGroupChange = (value: string) => {
    setGroupId(value);
    if (value) {
      setInitiativeError(false);
      setGroupError(false);
      setUserError(false);
    } else if (value && !initiativeId) {
      setInitiativeError(true);
    }
  };

  const onUserChange = (value: string) => {
    setUserId(value);
    if (value) {
      setInitiativeError(false);
      setGroupError(false);
      setUserError(false);
    }
  };

  const [groups, setGroups] = useState<Group[]>([]);
  const [users, setUsers] = useState<Employee[]>([]);

  const initiatives = useAppSelector(selectInitiatives);

  useEffect(() => {
    dispatch(fetchInitiatives());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (initiativeId) {
        const groups: Group[] = await getInitiativeGroups(initiativeId!);
        setGroups(groups);
      }
    })();
  }, [params.initiativeId, initiativeId]);

  useEffect(() => {
    (async () => {
      if (!initiativeId) {
        const employesData: Employee[] = await getEmployees();
        setUsers(employesData);
      }
    })();
  }, [initiativeId]);

  useEffect(() => {
    const group = groups.filter((group) => group.id === groupId).pop();
    group && setUsers(group.users);
  }, [groupId, groups]);

  const [isInitiativesSelectOpen, setIsInitiativesSelectOpen] = useState(false);
  const [selectedInitiativeOption, setSelectedInitiativeOption] = useState({
    title: '',
  });

  const togglingInitiatives = () => {
    setIsGroupsSelectOpen(false);
    setIsUsersSelectOpen(false);
    setIsInitiativesSelectOpen(!isInitiativesSelectOpen);
  };

  const onInitiativeOptionClicked = (option: Initiative) => () => {
    onInitiativeChange(option.id);
    setIsInitiativesSelectOpen(false);
    setSelectedInitiativeOption(option);
  };

  const [isGroupsSelectOpen, setIsGroupsSelectOpen] = useState(false);
  const [selectedGroupOption, setSelectedGroupOption] = useState({
    title: '',
  });

  const togglingGroups = () => {
    setIsUsersSelectOpen(false);
    setIsInitiativesSelectOpen(false);
    setIsGroupsSelectOpen(!isGroupsSelectOpen);
  };

  const onGroupOptionClicked = (option: Group) => () => {
    onGroupChange(option.id);
    setIsGroupsSelectOpen(false);
    setSelectedGroupOption(option);
  };

  const [isUsersSelectOpen, setIsUsersSelectOpen] = useState(false);
  const [selectedUserOption, setSelectedUserOption] = useState({
    name: '',
    surname: '',
  });

  const togglingUsers = () => {
    setIsGroupsSelectOpen(false);
    setIsInitiativesSelectOpen(false);
    setIsUsersSelectOpen(!isUsersSelectOpen);
  };

  const onUserOptionClicked = (option: any) => () => {
    onUserChange(option.id);
    setIsUsersSelectOpen(false);
    setSelectedUserOption(option);
  };

  const resetFields = () => {
    setTitle('');
    setContent('');
    setSelectedInitiativeOption({
      title: '',
    });
    setSelectedGroupOption({
      title: '',
    });
    setSelectedUserOption({
      name: '',
      surname: '',
    });
  };

  const handleOnSendNotificationClick = async (e: MouseEvent) => {
    e.preventDefault();
    if (!title) {
      setTitleError(true);
    }
    if (!content) {
      setContentError(true);
    }
    if (!initiativeId && groupId) {
      setInitiativeError(true);
    }
    if (!initiativeId && !groupId && !userId) {
      setInitiativeError(true);
      setGroupError(true);
      setUserError(true);
    }

    try {
      const data: NotificationData = { title, content };
      if (title && content && groupId && initiativeId && userId) {
        await createNotificationForUser(userId!, data);
        dispatch(setSuccess(t('notification_sended')));
        resetFields();
        setTimeout(() => dispatch(resetSuccess()), 4000);
      } else if (title && content && groupId && initiativeId) {
        await createNotificationForGroup(groupId!, data);
        dispatch(setSuccess(t('notification_sended')));
        resetFields();
        setTimeout(() => dispatch(resetSuccess()), 4000);
      } else if (title && content && initiativeId) {
        await createNotificationForInitiative(initiativeId!, data);
        dispatch(setSuccess(t('notification_sended')));
        resetFields();
        setTimeout(() => dispatch(resetSuccess()), 4000);
      }
    } catch (e) {
      //
    }
  };

  useEffect(() => {
    const option = initiatives
      .filter((initiative) => initiative.id === initiativeId)
      .pop();
    onInitiativeChange(option?.id || '');
    setSelectedInitiativeOption(
      option || {
        title: '',
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="section">
        <div className="container">
          <p className="section-title">{t('push_notification')}</p>
          <div className="notifications-title-with-icon">
            <img src={notificationsIcon} alt="notifications-icon" />
            <p className="label">{t('notification_label')}</p>
          </div>
          <div className="create-notification-form">
            <form>
              <div className="create-notification-form-column">
                <div className="form-group">
                  <label className={titleError ? 'label-error' : ''}>
                    {titleError
                      ? t('error_wrong_message_title')
                      : t('message_title')}
                  </label>
                  <input
                    type="text"
                    className={
                      titleError
                        ? 'form-input-title input-error'
                        : 'form-input-title'
                    }
                    value={title}
                    onChange={(e) => onTitleChange(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label className={contentError ? 'label-error' : ''}>
                    {contentError
                      ? t('error_wrong_message_content')
                      : t('message_content')}
                  </label>
                  <textarea
                    className={
                      contentError
                        ? 'form-textarea-content input-error'
                        : 'form-textarea-content'
                    }
                    value={content}
                    onChange={(e) => onContentChange(e.target.value)}
                  />
                </div>
              </div>
              <div className="create-notification-form-column">
                <div className="form-group">
                  <label className={initiativeError ? 'label-error' : ''}>
                    {t('initiative_pick')}
                  </label>
                  <div
                    className={
                      initiativeError
                        ? 'form-select input-error'
                        : 'form-select'
                    }
                  >
                    <div
                      className="dropdown-header"
                      onClick={togglingInitiatives}
                    >
                      <img
                        src={arrowIcon}
                        alt="arrow-icon"
                        className="arrow-icon"
                      />
                      {selectedInitiativeOption.title}
                    </div>
                    {isInitiativesSelectOpen && (
                      <div className="dropdown-list-container">
                        <ul className="dropdown-list">
                          {initiatives.map((initiative, i) => (
                            <li
                              className="list-item"
                              onClick={onInitiativeOptionClicked(initiative)}
                              key={i}
                            >
                              {initiative.title}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label className={groupError ? 'label-error' : ''}>
                    {t('team_pick')}
                  </label>
                  <div
                    className={
                      groupError ? 'form-select input-error' : 'form-select'
                    }
                  >
                    <div className="dropdown-header" onClick={togglingGroups}>
                      <img
                        src={arrowIcon}
                        alt="arrow-icon"
                        className="arrow-icon"
                      />
                      {selectedGroupOption.title}
                    </div>
                    {isGroupsSelectOpen && (
                      <div className="dropdown-list-container">
                        <ul className="dropdown-list">
                          {groups.map((group, i) => (
                            <li
                              className="list-item"
                              onClick={onGroupOptionClicked(group)}
                              key={i}
                            >
                              {group.title}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label className={userError ? 'label-error' : ''}>
                    {t('pick_person')}
                  </label>
                  <div
                    className={
                      userError ? 'form-select input-error' : 'form-select'
                    }
                  >
                    <div className="dropdown-header" onClick={togglingUsers}>
                      <img
                        src={arrowIcon}
                        alt="arrow-icon"
                        className="arrow-icon"
                      />
                      {selectedUserOption.name} {selectedUserOption.surname}
                    </div>
                    {isUsersSelectOpen && (
                      <div className="dropdown-list-container">
                        <ul className="dropdown-list">
                          {users.map((user, i) => (
                            <li
                              className="list-item"
                              onClick={onUserOptionClicked(user)}
                              key={i}
                            >
                              {user.name} {user.surname}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <button
                  className="button-green button-submit-notification"
                  onClick={handleOnSendNotificationClick}
                >
                  {t('notification_send')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationsForUserScreen;
