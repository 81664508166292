import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../hooks/hooks';
import { displayNewGroupModal } from '../redux/modalsSlice';
import { removeNewGroup } from '../redux/groupAddSlice';
import { NewGroup } from '../types/groups';
import deleteIcon from '../static/icons/bin.svg';

const GroupAdd = ({ group }: { group: NewGroup }) => {
  const { title, users, id } = group;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onEditGroupClick = () => {
    dispatch(displayNewGroupModal());
  };

  const handleOnRemoveGroupClick = () => {
    dispatch(removeNewGroup());
  };

  const isEmpty = users!.length === 0;

  return (
    <div className={isEmpty ? 'group-add-empty' : 'group'}>
      <div className="group-avatar">
        <p className="group-avatar-inner">{id}</p>
      </div>
      <p className="title">{title}</p>
      <p className="users">
        {t('team_amount')} <strong>{users!.length}</strong>
      </p>
      <button
        className={
          isEmpty
            ? 'button-edit-group button-violet'
            : 'button-edit-group button-violet-white'
        }
        onClick={onEditGroupClick}
      >
        {isEmpty ? t('add_users') : t('team_edit')}
      </button>
      <button
        className={
          isEmpty
            ? 'button-group-creation-remove'
            : 'button-group-creation-remove-white'
        }
        onClick={handleOnRemoveGroupClick}
      >
        <img src={deleteIcon} className="remove-icon" alt="remove-icon" />
      </button>
    </div>
  );
};

export default GroupAdd;
