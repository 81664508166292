import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InitiativeComponent from './Initiative';
import { getEmployees } from '../api/employees';
import { getInitiatives } from '../api/initiatives';
import { useAppSelector, useAppDispatch } from '../hooks/hooks';
import { selectIsUserLogged } from '../redux/authSlice';
import { fetchInitiatives, selectInitiatives } from '../redux/initiativesSlice';
import { fetchEmployees } from '../redux/employeesSlice';
import { enableInitialFlow } from '../redux/initialFlowSlice';
import { Initiative, InitiativeStatus } from '../types/initiatives';
import addEmployeesIcon from '../static/icons/add-employees.svg';
import addInitiativeIcon from '../static/icons/add-initiative.svg';

const DashboardScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchInitiatives());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchEmployees());
  }, [dispatch]);

  const isUserLogged = useAppSelector(selectIsUserLogged);
  const initiatives = useAppSelector(selectInitiatives);

  const current = initiatives.filter(
    (initiative: Initiative) =>
      initiative.status === InitiativeStatus.ACTIVE ||
      initiative.status === InitiativeStatus.DRAFT
  );

  const historical = initiatives.filter(
    (initiative: Initiative) =>
      initiative.status === InitiativeStatus.HISTORICAL
  );

  const [currentInitiatives, setCurrentInitiatives] = useState<Initiative[]>(
    []
  );
  const [historicalInitiatives, setHistoricalInitiatives] = useState<
    Initiative[]
  >([]);
  const [sortType, setSortType] = useState<string>('');

  useEffect(() => {
    (async () => {
      const employees = await getEmployees();
      if (!employees.length) {
        return navigate('/employees/add/initial');
      }
      const initiatives = await getInitiatives();
      if (!initiatives.length) {
        return navigate('/initiatives/create-new/initial');
      }
    })();
  }, [navigate]);

  useEffect(() => {
    setCurrentInitiatives(current);
    setHistoricalInitiatives(historical);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiatives]);

  useEffect(() => {
    if (!isUserLogged) {
      return navigate('/login');
    }
  }, [isUserLogged, navigate]);

  const onSortCurrentByTitleClick = () => {
    setSortType('title');
    const sortedInitiatives = current.sort((a: Initiative, b: Initiative) =>
      a.title > b.title ? 1 : -1
    );
    setCurrentInitiatives(sortedInitiatives);
  };

  const onSortCurrentByDateClick = () => {
    setSortType('date');
    const sortedInitiatives = current.sort((a: Initiative, b: Initiative) =>
      a.dateFrom > b.dateFrom ? 1 : -1
    );
    setCurrentInitiatives(sortedInitiatives);
  };

  const onSortHistoricalByTitleClick = () => {
    setSortType('title');
    const sortedInitiatives = historical.sort((a: Initiative, b: Initiative) =>
      a.title > b.title ? 1 : -1
    );
    setHistoricalInitiatives(sortedInitiatives);
  };

  const onSortHistoricalByDateClick = () => {
    setSortType('date');
    const sortedInitiatives = historical.sort((a: Initiative, b: Initiative) =>
      a.dateFrom > b.dateFrom ? 1 : -1
    );
    setHistoricalInitiatives(sortedInitiatives);
  };

  const onCreateNewInitiativeClick = () => {
    dispatch(enableInitialFlow());
    return navigate('/initiatives/create-new');
  };

  const onAddUsersClick = () => {
    dispatch(enableInitialFlow());
    return navigate('/employees/add');
  };

  return (
    <>
      <div className="dashboard-actions">
        <button className="button-add-employees" onClick={onAddUsersClick}>
          <img
            src={addEmployeesIcon}
            alt="add-employees-icon"
            className="add-employees-icon"
          />{' '}
          {t('dashboard_add_employers')}
        </button>
        <button
          className="button-add-initiative"
          onClick={onCreateNewInitiativeClick}
        >
          <img
            src={addInitiativeIcon}
            alt="add-initiative-icon"
            className="add-initiative-icon"
          />{' '}
          {t('dashboard_add_initiative')}
        </button>
      </div>

      <div className="section">
        <div className="container">
          {currentInitiatives.length ? (
            <>
              <p className="section-title">{t('dashboard_initiatives')}</p>
              <div className="sorter">
                <p>{t('dashboard_sort')}</p>
                <button
                  onClick={onSortCurrentByTitleClick}
                  className={sortType === 'title' ? 'active' : ''}
                >
                  {t('dashboard_sort_alphabetically')}
                </button>
                <button
                  onClick={onSortCurrentByDateClick}
                  className={sortType === 'date' ? 'active' : ''}
                >
                  {t('dashboard_date')}
                </button>
              </div>
              {currentInitiatives.map((initiative: Initiative) => (
                <InitiativeComponent
                  initiative={initiative}
                  key={initiative.id}
                />
              ))}
            </>
          ) : null}
        </div>
      </div>
      <div className="section">
        <div className="container">
          {historicalInitiatives.length ? (
            <>
              <p className="section-title">
                {t('dashboard_ended_initiatives')}
              </p>
              <div className="sorter">
                <p>{t('dashboard_sort')}</p>
                <button
                  onClick={onSortHistoricalByTitleClick}
                  className={sortType === 'title' ? 'active' : ''}
                >
                  {t('dashboard_sort_alphabetically')}
                </button>
                <button
                  onClick={onSortHistoricalByDateClick}
                  className={sortType === 'date' ? 'active' : ''}
                >
                  {t('dashboard_date')}
                </button>
              </div>
              {historicalInitiatives.map((initiative: Initiative) => (
                <InitiativeComponent
                  initiative={initiative}
                  key={initiative.id}
                />
              ))}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default DashboardScreen;
