import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import { NewGroup } from '../types/groups';
import { Employee } from '../types/employee';

export interface GroupsState {
  data: NewGroup;
}

const initialState: GroupsState = {
  data: {},
};

export const groupAddSlice = createSlice({
  name: 'groupAdd',
  initialState,
  reducers: {
    addNewGroup: (state) => {
      state.data = { id: '1', title: 'Drużyna 1', users: [] };
    },
    removeNewGroup: (state) => {
      state.data = {};
    },
    resetNewGroup: (state) => {
      state.data = { id: '1', title: 'Drużyna 1', users: [] };
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.data.title = action.payload;
    },
    addNewUser: (state, action: PayloadAction<{ user: Employee }>) => {
      state.data.users = [...state.data.users!, action.payload.user];
    },
    removeExistingUser: (
      state,
      action: PayloadAction<{ id?: number; user: Employee }>
    ) => {
      const users = [...state.data.users!];
      state.data.users = users.filter(
        (user) => user.id !== action.payload.user.id
      );
    },
  },
});

export const {
  addNewGroup,
  removeNewGroup,
  resetNewGroup,
  setTitle,
  addNewUser,
  removeExistingUser,
} = groupAddSlice.actions;

export const selectAddedGroup = (state: RootState) => state.groupAdd.data;

export const selectUsersWithoutGroup = (state: RootState) => {
  let usersInGroupsArray: any[] = [];
  state.initiatives.groups.map((group) => usersInGroupsArray.push(group.users));
  const usersInGroups = usersInGroupsArray.flat();
  const employees = state.employees.data;

  const usersWithoutGroup = employees.filter((employee) =>
    usersInGroups.every((user: Employee) => !(user.email === employee.email))
  );

  return usersWithoutGroup;
};

export default groupAddSlice.reducer;
