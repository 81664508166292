import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';

export interface InitialFlowState {
  isActive: boolean;
  currentStep: number;
}

const initialState: InitialFlowState = {
  isActive: true,
  currentStep: 1,
};

export const initialFlow = createSlice({
  name: 'initialFlow',
  initialState,
  reducers: {
    enableInitialFlow: (state) => {
      state.isActive = true;
    },
    disableInitialFlow: (state) => {
      state.isActive = false;
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
  },
});

export const { enableInitialFlow, disableInitialFlow, setCurrentStep } =
  initialFlow.actions;

export const selectInitialFlowIsActive = (state: RootState) =>
  state.initialFlow.isActive;

export const selectInitialFlowCurrentStep = (state: RootState) =>
  state.initialFlow.currentStep;

export default initialFlow.reducer;
