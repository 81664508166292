import qs from 'qs';
import httpApi from './api';
import { Group, CreateGroup } from '../types/groups';

export const getInitiativeGroups = async (
  id: string | number
): Promise<Group[]> => {
  return await httpApi
    .get(`/v1/hr/initiatives/${id}/groups`)
    .then((res) => res.data.data);
};

export const addGroupToInitiative = async (
  id: string,
  data: CreateGroup
): Promise<Group> => {
  const employeeIds = data.users.map((user) => user.id);
  const { title } = data;
  return await httpApi.post(
    `/v1/hr/initiatives/${id}/groups`,
    qs.stringify({ title, userId: employeeIds }, { indices: false })
  );
};

export const getInitiativeGroup = async (groupId: string): Promise<Group> => {
  return await httpApi
    .get(`/v1/hr/initiative-groups/${groupId}`)
    .then((res) => res.data.data);
};

export const editInitiativeGroup = async (data: Group): Promise<Group> => {
  const employeeIds = data.users.map((user) => user.id);
  const { title } = data;
  return await httpApi.put(
    `/v1/hr/initiative-groups/${data.id}`,
    qs.stringify({ title, userId: employeeIds }, { indices: false })
  );
};

export const deleteGroupFromInitiative = async (
  groupId: string
): Promise<void> => {
  return await httpApi.delete(`/v1/hr/initiative-groups/${groupId}`);
};

export const getShuffledGroupsByGroupSize = async (
  groupSize: number
): Promise<Group[]> => {
  return await httpApi
    .get(`/v1/hr/employees/groups-shuffle/groupSize/${groupSize}`)
    .then((res) => res.data.data);
};

export const getShuffledGroupsByGroupNumber = async (
  groupNumber: number
): Promise<Group[]> => {
  return await httpApi
    .get(`/v1/hr/employees/groups-shuffle/groupNumber/${groupNumber}`)
    .then((res) => res.data.data);
};
