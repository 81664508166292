import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InitialFlowStepper from './InitialFlowStepper';
import InitialFlowFooter from './InitialFlowFooter';
import { useNavigate } from 'react-router-dom';
import { Employee } from '../types/employee';
import { useAppSelector, useAppDispatch } from '../hooks/hooks';
import {
  selectInitialFlowIsActive,
  setCurrentStep,
} from '../redux/initialFlowSlice';
import { addUser, setInitialGroups } from '../redux/groupsCreationSlice';
import { selectEmployees } from '../redux/employeesSlice';
import addAllGroupIcon from '../static/icons/add-all-group.svg';
import addManualGroupIcon from '../static/icons/add-manual-group.svg';
import addAutoGroupIcon from '../static/icons/add-auto-group.svg';

const AddGroupsScreen = () => {
  const initialFlow = useAppSelector(selectInitialFlowIsActive);
  const employees: Employee[] = useAppSelector(selectEmployees);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setCurrentStep(3));
  }, [dispatch]);

  const handleOnAddAllToOneGroupClick = () => {
    dispatch(setInitialGroups());
    employees.forEach((user: Employee) => {
      dispatch(addUser({ id: 1, user }));
    });
    return navigate('/initiatives/create-new/groups/add-all');
  };

  const handleOnAddManualGroupsClick = () => {
    dispatch(setInitialGroups());
    return navigate('/initiatives/create-new/groups/add-manual');
  };

  const handleOnAddShuffledGroupsClick = () => {
    return navigate('/initiatives/create-new/groups/add-shuffled');
  };

  return (
    <>
      {initialFlow && <InitialFlowStepper />}
      <div className="section">
        <div className="container">
          <p className="section-title">{t('company_team')}</p>
          <p className="label">{t('company_team_label')}</p>
          <p className="create-group-form-title">{t('company_team_option1')}</p>
          <div className="create-group-form">
            <p className="label">
              <img src={addAllGroupIcon} alt="add-all-group" />
              {employees.length === 1
                ? t('company_team_option1_label2', {
                    employees: employees.length || '-',
                  })
                : t('company_team_option1_label', {
                    employees: employees.length || '-',
                  })}
            </p>
            <button
              onClick={handleOnAddAllToOneGroupClick}
              className="button-green"
            >
              {t('company_team_add_all')}
            </button>
          </div>
          <p className="create-group-form-title">{t('company_team_option2')}</p>
          <div className="create-groups">
            <div className="create-group-form">
              <p className="title-with-icon">
                <img src={addManualGroupIcon} alt="add-manual-group" />
                {t('manually')}
              </p>
              <p className="label">{t('company_team_pick_from_list')}</p>
              <button
                onClick={handleOnAddManualGroupsClick}
                className="button-green"
              >
                {t('company_team_create_manually')}
              </button>
            </div>
            <div className="create-group-form">
              <p className="title-with-icon">
                <img src={addAutoGroupIcon} alt="add-auto-group" />
                {t('automatically')}
              </p>
              <p className="label">{t('company_team_lottery')}</p>
              <button
                onClick={handleOnAddShuffledGroupsClick}
                className="button-green"
              >
                {t('company_team_create_automatically')}
              </button>
            </div>
          </div>
        </div>
      </div>
      {initialFlow && (
        <InitialFlowFooter
          forwardPath={'/initiatives/create-new/groups/add-manual'}
          backPath={'/initiatives/create-new'}
        />
      )}
    </>
  );
};

export default AddGroupsScreen;
