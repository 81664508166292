import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import { formatDate, formatISODate } from '../helpers/dates';

export interface NewInitiativeState {
  dateFrom: string;
  dateTo: string;
  description: string;
  goal: string;
  photo: string;
  title: string;
}

const serializedInitiativeCreation = localStorage.getItem(
  'initiative-creation'
);

const initiativeCreationData = JSON.parse(serializedInitiativeCreation!);

const initialState: NewInitiativeState = {
  dateFrom: (initiativeCreationData && initiativeCreationData.dateFrom) || '',
  dateTo: (initiativeCreationData && initiativeCreationData.dateTo) || '',
  description:
    (initiativeCreationData && initiativeCreationData.description) || '',
  goal: (initiativeCreationData && initiativeCreationData.goal) || '',
  photo: (initiativeCreationData && initiativeCreationData.photo) || '',
  title: (initiativeCreationData && initiativeCreationData.title) || '',
};

export const initiativeCreation = createSlice({
  name: 'initiativeCreation',
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
      localStorage.setItem('initiative-creation', JSON.stringify(state));
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
      localStorage.setItem('initiative-creation', JSON.stringify(state));
    },
    setGoal: (state, action: PayloadAction<string>) => {
      state.goal = action.payload;
      localStorage.setItem('initiative-creation', JSON.stringify(state));
    },
    setDates: (state, action: PayloadAction<string>) => {
      const date = new Date(action.payload);
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      state.dateFrom = formatDate(firstDay);
      state.dateTo = formatDate(lastDay);
      localStorage.setItem('initiative-creation', JSON.stringify(state));
    },
    setDateFrom: (state, action: PayloadAction<Date>) => {
      state.dateFrom = formatISODate(action.payload.toString());
      localStorage.setItem('initiative-creation', JSON.stringify(state));
    },
    setDateTo: (state, action: PayloadAction<Date>) => {
      state.dateTo = formatISODate(action.payload.toString());
      localStorage.setItem('initiative-creation', JSON.stringify(state));
    },
    setPhotoUrl: (state, action: PayloadAction<string>) => {
      state.photo = action.payload;
      localStorage.setItem('initiative-creation', JSON.stringify(state));
    },
    resetInitialInitiative: (state) => {
      localStorage.removeItem('initiative-creation');
      state.dateFrom = '';
      state.dateTo = '';
      state.description = '';
      state.goal = '';
      state.photo = '';
      state.title = '';
    },
  },
});

export const {
  setTitle,
  setDescription,
  setGoal,
  setDates,
  setPhotoUrl,
  resetInitialInitiative,
  setDateFrom,
  setDateTo,
} = initiativeCreation.actions;

export const selectNewInitiativeTitle = (state: RootState) =>
  state.initiativeCreation.title;

export const selectNewInitiativeDescription = (state: RootState) =>
  state.initiativeCreation.description;

export const selectNewInitiativeGoal = (state: RootState) =>
  state.initiativeCreation.goal;

export const selectNewInitiativeDateFrom = (state: RootState) =>
  state.initiativeCreation.dateFrom;

export const selectNewInitiativeDateTo = (state: RootState) =>
  state.initiativeCreation.dateTo;

export const selectNewInitiativePhotoUrl = (state: RootState) =>
  state.initiativeCreation.photo;

export default initiativeCreation.reducer;
