import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import {
  selectEditedGroupId,
  removeGroup,
  setEditedGroupId,
} from '../redux/groupsCreationSlice';
import { closeDeleteGroupModal } from '../redux/modalsSlice';
import deleteEmployeeIcon from '../static/icons/delete-employee.svg';
import closeIcon from '../static/icons/close.svg';

const DeleteGroupCreationModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const groupId = useAppSelector(selectEditedGroupId);

  const onCloseModalClick = () => {
    dispatch(closeDeleteGroupModal());
    dispatch(setEditedGroupId(0));
  };

  const handleOnSubmitClick = async () => {
    dispatch(removeGroup({ id: groupId }));
    dispatch(closeDeleteGroupModal());
    dispatch(setEditedGroupId(0));
  };
  return (
    <div className="dark-background">
      <div className="centered">
        <div className="modal confirm-modal">
          <div className="modal-header confirm-modal-header">
            <button onClick={onCloseModalClick}>
              <img src={closeIcon} alt="close-icon" className="close-icon" />
            </button>
          </div>
          <img
            src={deleteEmployeeIcon}
            alt="delete-employee-icon"
            className="delete-employee-icon"
          />
          <p className="label">{t('confirm_delete_team')}</p>
          <div className="modal-footer confirm-modal-footer">
            <button
              onClick={onCloseModalClick}
              className="button-white-green button-modal-cancel"
            >
              {t('no')}
            </button>
            <button onClick={handleOnSubmitClick} className="button-green">
              {t('yes')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteGroupCreationModal;
