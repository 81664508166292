import React, { useEffect, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../hooks/hooks';
import { setSuccess, resetSuccess } from '../redux/successSlice';
import {
  getForgotPasswordVerificationCode,
  finalizePasswordReset,
} from '../api/forgotPassword';
import GoForwardIcon from '../static/icons/go-forward.svg';

const ResetPasswordScreen = () => {
  const [searchParams] = useSearchParams();
  const verificationCode = searchParams.get('verificationCode');

  const [verificationCodeError, setVerificationCodeError] =
    useState<string>('');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const invalidLink = t('error_invalid_link');

  useEffect(() => {
    getForgotPasswordVerificationCode(verificationCode!).then((response) => {
      if (response.status !== 200) {
        setVerificationCodeError(invalidLink);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationCode]);

  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<any>('');

  const handleChangePassword = (value: string) => {
    setPassword(value);
    value.length < 6
      ? setPasswordError(t('error_invalid_password'))
      : setPasswordError(null);
  };

  const [secondPassword, setSecondPassword] = useState<string>('');
  const [secondPasswordError, setSecondPasswordError] = useState<any>('');

  const handleChangeSecondPassword = (value: string) => {
    setSecondPassword(value);
    value !== password
      ? setSecondPasswordError(t('error_password_not_identical'))
      : setSecondPasswordError(null);
  };

  const handleOnSetNewPasswordClick = async (e: MouseEvent) => {
    e.preventDefault();
    await finalizePasswordReset({ password }, verificationCode!);
    dispatch(setSuccess(t('reset_password_success')));
    setTimeout(() => dispatch(resetSuccess()), 4000);
    return navigate('/login');
  };

  return (
    <div className="login-screen">
      <div className="login-form">
        {verificationCodeError ? (
          <p className="header text-center">{verificationCodeError}</p>
        ) : (
          <>
            <p className="header">{t('reset_password_header')}</p>
            <p className="label">{t('reset_password_label')}</p>
            <form>
              <div className="form-group">
                <label className={passwordError ? 'label-error' : ''}>
                  {passwordError ? passwordError : t('login_password')}
                </label>
                <input
                  type="password"
                  className={
                    passwordError ? 'form-input input-error' : 'form-input'
                  }
                  onChange={(e) => handleChangePassword(e.target.value)}
                  value={password}
                />
              </div>
              <div className="form-group">
                <label className={secondPasswordError ? 'label-error' : ''}>
                  {secondPasswordError
                    ? secondPasswordError
                    : t('reset_password_repeat')}
                </label>
                <input
                  type="password"
                  className={
                    secondPasswordError
                      ? 'form-input input-error'
                      : 'form-input'
                  }
                  onChange={(e) => handleChangeSecondPassword(e.target.value)}
                  value={secondPassword}
                />
              </div>
              <button
                onClick={handleOnSetNewPasswordClick}
                className="button-submit-reset-password"
                disabled={
                  !password ||
                  !secondPassword ||
                  passwordError ||
                  secondPasswordError
                }
              >
                {t('reset_password_set_password')}
                <img
                  src={GoForwardIcon}
                  alt="go-forward-icon"
                  className="go-forward-icon"
                />
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
