import React from 'react';
import { useTranslation } from 'react-i18next';

const UserProfileScreen = () => {
  const { t } = useTranslation();
  const serialisedUser = localStorage.getItem('user');
  const user = serialisedUser && JSON.parse(serialisedUser!);

  return (
    <>
      <div className="section">
        <div className="container">
          <p className="section-title">{t('user_profile')}</p>
          <div className="user-profile-container">
            <div className="user-profile-labels">
              <p>{t('user_name')}</p>
              <p>{t('user_surname')}</p>
              <p>{t('user_email')}</p>
              <p>{t('user_organization')}</p>
            </div>
            <div className="user-profile-data">
              <p>{user && user.name}</p>
              <p>{user && user.surname}</p>
              <p>{user && user.email}</p>
              <p>{user && user.company.name}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfileScreen;
