import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';

export interface ErrorState {
  isError: boolean;
  errorMessage: string;
}

const initialState: ErrorState = {
  isError: false,
  errorMessage: '',
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.isError = true;
      state.errorMessage = action.payload;
    },
    resetError: (state) => {
      state.isError = false;
      state.errorMessage = '';
    },
  },
});

export const { setError, resetError } = errorSlice.actions;

export const selectError = (state: RootState) => state.error.errorMessage;

export default errorSlice.reducer;
