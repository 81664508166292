import React, { useEffect, useState, useRef, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import StatisticsInitiative from './StatisticsInitiativeScreen';
import EditGroupModal from './EditGroupModal';
import AddGroupModal from './EditGroupAddModal';
import DeleteInitiativeModal from './DeleteInitiativeModal';
import DeleteGroupModal from './DeleteGroupModal';
import MobileAppEditPreviewModal from './MobileAppEditPreviewModal';
import GroupExisting from './GroupExisting';
import GroupAdd from './GroupAdd';
import { useAppSelector, useAppDispatch } from '../hooks/hooks';
import {
  fetchInitiatives,
  selectInitiatives,
  setInitiativeGroups,
} from '../redux/initiativesSlice';
import { selectEditedGroup } from '../redux/groupEditSlice';
import { selectAddedGroup, addNewGroup } from '../redux/groupAddSlice';
import {
  selectDisplayGroupModal,
  selectDisplayNewGroupModal,
  selectDisplayDeleteInitiativeModal,
  displayDeleteInitiativeModal,
  selectDisplayDeleteGroupModal,
  selectDisplayMobileAppPreviewModal,
  displayMobileAppPreviewModal,
} from '../redux/modalsSlice';
import { disableInitialFlow } from '../redux/initialFlowSlice';
import { setSuccess, resetSuccess } from '../redux/successSlice';
import { selectError } from '../redux/errorSlice';
import { editInitiative, launchInitiative } from '../api/initiatives';
import { getInitiativeGroups } from '../api/groups';
import { getInitiativeStatistics } from '../api/statistics';
import { uploadPhoto } from '../api/photos';
import { Initiative, InitiativeStatus } from '../types/initiatives';
import { Group } from '../types/groups';
import { StatisticsData } from '../types/statistics';
import {
  calculateTimeLeft,
  calculateProgressDays,
  calculateProgress,
  formatDate,
  formatISODate,
} from '../helpers/dates';
import editIcon from '../static/icons/edit-gray.svg';
import statisticsIcon from '../static/icons/statistics.svg';
import notificationsIcon from '../static/icons/bell.svg';
import addUserIcon from '../static/icons/add-user.svg';
import addGroupIcon from '../static/icons/add-group.svg';
import uploadPhotoImage from '../static/images/upload-photo.svg';
import deletePhotoIcon from '../static/icons/bin-white.svg';
import cameraIcon from '../static/icons/camera.svg';
import createInitiativeIcon from '../static/icons/create-initiative.svg';
import DatePicker from './DatePicker';
import { addEmployee } from '../api/employees';
import { fetchEmployees } from '../redux/employeesSlice';

const EditInititativeScreen = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [groups, setGroups] = useState<Group[]>();
  const groupToEdit = useAppSelector(selectEditedGroup);
  const newGroup = useAppSelector(selectAddedGroup);

  const showEditGroupModal = useAppSelector(selectDisplayGroupModal);
  const showAddNewGroupModal = useAppSelector(selectDisplayNewGroupModal);
  const showDeleteInitiativeModal = useAppSelector(
    selectDisplayDeleteInitiativeModal
  );
  const showDeleteGroupModal = useAppSelector(selectDisplayDeleteGroupModal);
  const showMobileAppPreviewModal = useAppSelector(
    selectDisplayMobileAppPreviewModal
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const errorWrongFormat = t('error_wrong_format');
  const errorTooLargeFile = t('error_too_large_file');
  const errorWrongDimensions = t('error_wrong_dimensions');

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  useEffect(() => {
    dispatch(fetchInitiatives());
  }, [dispatch]);

  const initiatives = useAppSelector(selectInitiatives);
  const initiative = initiatives
    .filter((initiative: Initiative) => initiative.id === id)
    .pop();

  const [statistics, setStatistics] = useState<StatisticsData[]>([]);

  const [file, setFile] = useState<any>(null);
  const [uploadError, setUploadError] = useState<string>('');
  const [title, setTitle] = useState<string>(initiative! && initiative.title);
  const [description, setDescription] = useState<string>(
    initiative! && initiative.description
  );
  const [goal, setGoal] = useState<string>(initiative! && initiative.goal);

  const dateFrom: any = initiative! && initiative.dateFrom;
  const dateTo: any = initiative! && initiative.dateTo;
  const [dateFromInitiative, setDateFromInitiative] = useState<string>(
    dateFrom !== '' ? dateFrom : `${formatDate(new Date())}`
  );
  const [dateToInitiative, setDateToInitiative] = useState<string>(
    dateTo !== '' ? dateTo : `${formatDate(new Date())}`
  );

  const [photo, setPhotoUrl] = useState<string>(
    initiative! && initiative.photo
  );
  const [surnameError, setSurnameError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [added, setAdded] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<string>('...');

  const getStatus = () => {
    const { status } = initiative!;
    if (status === InitiativeStatus.ACTIVE) {
      return t('initiative_active');
    }
    if (status === InitiativeStatus.DRAFT) {
      return t('initiative_waiting');
    }
    if (status === InitiativeStatus.HISTORICAL) {
      return t('initiative_ended');
    }
    return status;
  };

  const isActive = initiative!.status === InitiativeStatus.ACTIVE;

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft(dateTo));
    }, 1000);
    return () => clearInterval(interval);
  }, [dateTo]);

  const [dragActive, setDragActive] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const groupsRef = useRef<HTMLDivElement>(null);

  const [surname, setSurname] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [newEmployeeModal, setNewEmployeeModal] = useState<boolean>(false);
  const [titleError, setTitleError] = useState<boolean>(false);
  const [descriptionError, setDescriptionError] = useState<boolean>(false);
  const [goalError, setGoalError] = useState<boolean>(false);
  const [titleToShortError, setTitleToShortError] = useState<boolean>(false);
  const [descriptionToShortError, setDescriptionToShortError] =
    useState<boolean>(false);
  const [goalToShortError, setGoalToShortError] = useState<boolean>(false);

  const onTitleChange = (value: string) => {
    setTitle(value);
    if (titleError || titleToShortError) {
      value.length < 255 ? setTitleError(false) : setTitleError(true);
      value.length > 5
        ? setTitleToShortError(false)
        : setTitleToShortError(true);
    }
  };

  const onTitleBlur = (value: string) => {
    value.length < 255 ? setTitleError(false) : setTitleError(true);
    value.length > 5 ? setTitleToShortError(false) : setTitleToShortError(true);
  };

  const onDescriptionChange = (value: string) => {
    setDescription(value);
    if (descriptionError || descriptionToShortError) {
      value.length < 2000
        ? setDescriptionError(false)
        : setDescriptionError(true);
      value.length > 5
        ? setDescriptionToShortError(false)
        : setDescriptionToShortError(true);
    }
  };

  const onDescriptionBlur = (value: string) => {
    value.length < 2000
      ? setDescriptionError(false)
      : setDescriptionError(true);
    value.length > 5
      ? setDescriptionToShortError(false)
      : setDescriptionToShortError(true);
  };

  const onGoalChange = (value: string) => {
    setGoal(value);
    if (goalError || goalToShortError) {
      value.length < 500 ? setGoalError(false) : setGoalError(true);
      value.length > 5 ? setGoalToShortError(false) : setGoalToShortError(true);
    }
  };

  const onGoalBlur = (value: string) => {
    value.length < 500 ? setGoalError(false) : setGoalError(true);
    value.length > 5 ? setGoalToShortError(false) : setGoalToShortError(true);
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    setFile(e.dataTransfer.files[0]);
    e.dataTransfer.files[0] && handleOnSubmitFile(e.dataTransfer.files[0]);
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    e.target.files[0] && handleOnSubmitFile(e.target.files[0]);
  };

  const onUploadImageButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    inputRef.current!.click();
  };

  const onDeleteImageButtonClick = () => {
    setPhotoUrl('');
    setFile(null);
  };

  const handleOnSubmitFile = async (file: any) => {
    setUploadError('');
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      return setUploadError(errorWrongFormat);
    } else if (file.size > 1000000) {
      return setUploadError(errorTooLargeFile);
    }
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.naturalWidth !== 750 || img.naturalHeight !== 362) {
        return setUploadError(errorWrongDimensions);
      }
    };
    const formData: FormData = new FormData();
    formData.append('photo', file);
    const { path } = await uploadPhoto(formData);
    path && setPhotoUrl(path);
    setUploadError('');
  };

  const handleOnStatisticsClick = () => {
    return navigate(`/statistics/initiative/${id}`);
  };

  const handleOnPushNotificationClick = () => {
    dispatch(disableInitialFlow());
    navigate(`/notifications/initiatives/${id}`);
  };

  const handleOnAddEmployeeClick = () => {
    dispatch(disableInitialFlow());
    setNewEmployeeModal(true);
  };

  const error = useAppSelector(selectError);
  useEffect(() => {
    (async () => {
      if (!error) {
        const groupsData: Group[] = await getInitiativeGroups(id!);
        setGroups(groupsData);
        dispatch(setInitiativeGroups(groupsData));
      }
    })();
  }, [id, groupToEdit, newGroup, dispatch, error]);

  useEffect(() => {
    (async () => {
      const statisticsData: StatisticsData[] = await getInitiativeStatistics(
        id!
      );
      setStatistics(statisticsData);
    })();
  }, [id]);

  const usersSum = groups
    ? groups
        .map((group) => group.users.length)
        .reduce((prev, curr) => prev + curr, 0)
    : 0;

  const stepsSum = statistics
    ? statistics
        .map((day: StatisticsData) => day.value)
        .reduce((prev: number, curr: number) => prev + curr, 0)
    : 0;

  const handleOnAddGroupClick = () => {
    dispatch(addNewGroup());
  };

  const onShowPreviewModalButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(displayMobileAppPreviewModal());
  };

  const onSaveInitiativeClick = async () => {
    const data: Initiative = {
      id: initiative!.id,
      title,
      description,
      goal,
      dateTo: dateToInitiative,
      dateFrom: dateFromInitiative,
      photo,
      status: initiative!.status,
    };
    await editInitiative(id!, data);
    await dispatch(fetchInitiatives());
    dispatch(setSuccess(t('changes_saved')));
    setTimeout(() => dispatch(resetSuccess()), 4000);
  };

  const onLaunchInitiativeClick = async () => {
    await launchInitiative(id!);
    await dispatch(fetchInitiatives());
    dispatch(setSuccess(t('initiative_started')));
    setTimeout(() => dispatch(resetSuccess()), 4000);
  };

  const onDeleteInitiativeClick = () => {
    dispatch(displayDeleteInitiativeModal());
  };

  const handleDateFromOnChange = (date: Date) => {
    const dateFrom = formatISODate(`${date}`);
    setDateFromInitiative(dateFrom);
  };

  const handleDateToOnChange = (date: Date) => {
    const dateTo = formatISODate(`${date}`);
    setDateToInitiative(dateTo);
  };

  useEffect(() => {
    const comparison = dateFromInitiative > dateToInitiative;
    if (comparison) {
      setDateToInitiative(dateFromInitiative);
    }
  }, [dateFromInitiative, dateToInitiative]);

  useEffect(() => {
    const comparison = dateFromInitiative > dateToInitiative;
    if (comparison) {
      setDateFromInitiative(dateToInitiative);
    }
  }, [dateFromInitiative, dateToInitiative]);

  const handleOnAddUserClick = async () => {
    const isEmptyName = name.trim().length === 0;
    const isEmptySurname = surname.trim().length === 0;

    if (!email) {
      setEmailError(true);
    }
    if (!surname || isEmptySurname) {
      setSurnameError(true);
    }
    if (!name || isEmptyName) {
      setNameError(true);
    }
    if (
      !emailError &&
      !surnameError &&
      !nameError &&
      !isEmptyName &&
      !isEmptySurname
    ) {
      if (isValidEmail(email) && surname.length > 2 && name.length > 2) {
        try {
          await addEmployee({ surname, name, email });
          setAdded(true);
          setSurname('');
          setName('');
          setEmail('');
          dispatch(fetchEmployees());
        } catch {
          //
        }
      }
    }
  };

  const onSurnameChange = (value: string) => {
    setSurname(value);
    value.length > 2 ? setSurnameError(false) : setSurnameError(true);
  };

  const onNameChange = (value: string) => {
    setName(value);
    value.length > 2 ? setNameError(false) : setNameError(true);
  };

  const onEmailChange = (value: string) => {
    setEmail(value);
    isValidEmail(value) || !value ? setEmailError(false) : setEmailError(true);
  };

  return (
    initiative! && (
      <>
        <div className="section section">
          <div className="container">
            <p className="section-title">{t('initiative_details')}</p>
            <div className="edit-initiative-title">
              <p>{title}</p>
            </div>
            <div className="edit-initiative-action-bar">
              <button
                onClick={handleOnStatisticsClick}
                className="button-edit-initiative-action-bar"
              >
                <img
                  src={statisticsIcon}
                  alt="statistics-icon"
                  className="statistics-icon"
                />
                {t('statistics')}
              </button>
              <button
                onClick={handleOnPushNotificationClick}
                className="button-edit-initiative-action-bar"
              >
                <img
                  src={notificationsIcon}
                  alt="notifications-icon"
                  className="notifications-icon"
                />
                {t('notifications')}
              </button>
              <button
                onClick={handleOnAddEmployeeClick}
                className="button-edit-initiative-action-bar"
              >
                <img
                  src={addUserIcon}
                  alt="add-user-icon"
                  className="add-user-icon"
                />
                {t('add_user')}
              </button>
              <button
                onClick={() =>
                  window.scrollTo({
                    behavior: 'smooth',
                    top: groupsRef.current!.offsetTop,
                  })
                }
                className="button-edit-initiative-action-bar"
              >
                <img
                  src={addGroupIcon}
                  alt="add-group-icon"
                  className="add-group-icon"
                />
                {t('add_teams')}
              </button>
            </div>
            {newEmployeeModal && (
              <div className="edit-initiative-action-bar">
                <div>
                  <div className="title-with-icon">
                    <p className="label">{t('add_user')}</p>
                  </div>
                  <div className="add-user-form">
                    <div className="form-group">
                      <label
                        htmlFor="surname"
                        className={surnameError ? 'label-error' : ''}
                      >
                        {surnameError ? t('error_wrong_surname') : t('surname')}
                      </label>
                      <input
                        type="text"
                        className={
                          surnameError ? 'form-input input-error' : 'form-input'
                        }
                        onChange={(e) => onSurnameChange(e.target.value)}
                        value={surname}
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="name"
                        className={nameError ? 'label-error' : ''}
                      >
                        {nameError ? t('error_wrong_name') : t('name')}
                      </label>
                      <input
                        type="text"
                        className={
                          nameError ? 'form-input input-error' : 'form-input'
                        }
                        onChange={(e) => onNameChange(e.target.value)}
                        value={name}
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="email"
                        className={emailError ? 'label-error' : ''}
                      >
                        {emailError ? t('error_wrong_email') : t('email')}
                      </label>
                      <input
                        type="email"
                        className={
                          emailError ? 'form-input input-error' : 'form-input'
                        }
                        onChange={(e) => onEmailChange(e.target.value)}
                        value={email}
                      />
                    </div>
                    <button
                      className="button-green"
                      onClick={handleOnAddUserClick}
                    >
                      {t('add_user')}
                    </button>
                  </div>
                  {added && (
                    <p className="section-title">{t('add_user_success')}</p>
                  )}
                </div>
              </div>
            )}
            <div className="edit-initiative-details">
              <div className="progress">
                <div className="progress-data">
                  <p>{t('initiative_progress')}</p>
                  <p className="number">
                    {calculateProgressDays(dateFrom, dateTo)} {t('days')}
                  </p>
                </div>
                <div className="progressbar-container">
                  <div
                    style={{
                      width: `${calculateProgress(dateFrom, dateTo)}%`,
                    }}
                    className="progressbar-filler"
                  ></div>
                </div>
              </div>
              <div className="summary">
                <div className="summary-labels">
                  <p>{t('status')}</p>
                  <p>{t('duration')}</p>
                  <p>{t('time_till_end')}</p>
                  <p>{t('teams_number')}</p>
                  <p>{t('users_number')}</p>
                  <p>{t('steps_made')}</p>
                </div>
                <div className="summary-data">
                  <p>{getStatus()}</p>
                  <p>
                    {dateFrom} - {dateTo}
                  </p>
                  <p>{isActive ? timeLeft : '-'}</p>
                  <p>{groups ? groups!.length : 0}</p>
                  <p>{usersSum}</p>
                  <p>{stepsSum.toLocaleString().replaceAll(',', ' ')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <StatisticsInitiative initiativeTitle={false} />
        <div className="section" ref={groupsRef}>
          <div className="container">
            <p className="section-title">{t('teams_involved')}</p>
            {groups &&
              groups.map((group: Group) => (
                <div key={group.id}>
                  <GroupExisting
                    group={group}
                    initiativeId={id!}
                    key={group.id}
                  />
                </div>
              ))}
            {newGroup && newGroup.users && <GroupAdd group={newGroup!} />}
            {!newGroup.users && (
              <button
                className="button-add-group button-violet"
                onClick={handleOnAddGroupClick}
              >
                {t('add_team')}
              </button>
            )}
          </div>
        </div>
        {showEditGroupModal && <EditGroupModal group={groupToEdit} />}
        {showAddNewGroupModal && <AddGroupModal group={newGroup} />}
        {/* DANE INICJATYWY */}
        <div className="section section-white">
          <div className="container">
            <div className="initiative-title-with-icon">
              <img src={createInitiativeIcon} alt="create-initiative-icon" />
              <div>
                <p className="title">{t('add_description')}</p>
                <p className="label">{t('check-description')}</p>
              </div>
            </div>
            <div className="create-initative-form">
              <form>
                <div className="create-initative-form-column">
                  <div className="form-group form-group-edit">
                    <label
                      className={
                        titleError || titleToShortError ? 'label-error' : ''
                      }
                    >
                      {t('initiative_title')}
                      {titleError &&
                        ` (${t('error_long_title').toLocaleLowerCase()})`}
                      {titleToShortError &&
                        ` (${t('error_short_title').toLocaleLowerCase()})`}
                    </label>
                    <input
                      type="text"
                      className={
                        titleError || titleToShortError
                          ? 'form-input input-error'
                          : 'form-input'
                      }
                      onChange={(e) => onTitleChange(e.target.value)}
                      onBlur={(e) => onTitleBlur(e.target.value)}
                      value={title}
                    />
                    <img
                      src={editIcon}
                      alt="edit-icon"
                      className="input-edit-icon"
                    />
                  </div>
                  <div className="form-group form-group-edit">
                    <label
                      className={
                        descriptionError || descriptionToShortError
                          ? 'label-error'
                          : ''
                      }
                    >
                      {t('initiative_description')}
                      {descriptionError &&
                        ` (${t('error_long_description').toLocaleLowerCase()})`}
                      {descriptionToShortError &&
                        ` (${t(
                          'error_short_description'
                        ).toLocaleLowerCase()})`}
                    </label>
                    <textarea
                      className={
                        descriptionError || descriptionToShortError
                          ? 'form-textarea-description input-error'
                          : 'form-textarea-description'
                      }
                      onChange={(e) => onDescriptionChange(e.target.value)}
                      onBlur={(e) => onDescriptionBlur(e.target.value)}
                      value={description}
                    />
                    <img
                      src={editIcon}
                      alt="edit-icon"
                      className="input-edit-icon"
                    />
                  </div>
                  <div className="form-group">
                    <label
                      className={
                        goalError || goalToShortError ? 'label-error' : ''
                      }
                    >
                      {t('initiative_goal')}
                      {goalError &&
                        ` (${t('error_long_goal').toLocaleLowerCase()})`}
                      {goalToShortError &&
                        ` (${t('error_short_goal').toLocaleLowerCase()})`}
                    </label>
                    <textarea
                      className={
                        goalError || goalToShortError
                          ? 'form-textarea-goal input-error'
                          : 'form-textarea-goal'
                      }
                      onChange={(e) => onGoalChange(e.target.value)}
                      onBlur={(e) => onGoalBlur(e.target.value)}
                      value={goal}
                    />
                    <img
                      src={editIcon}
                      alt="edit-icon"
                      className="input-edit-icon"
                    />
                  </div>
                </div>
                <div className="create-initative-form-column">
                  <div className="form-group-row">
                    <div className="form-group form-group-edit">
                      <label>{t('pick_month')}</label>
                      <div className="form-select-range">
                        <DatePicker
                          className="form-input"
                          selected={new Date(dateFromInitiative)}
                          value={new Date(dateFromInitiative)}
                          onChange={handleDateFromOnChange}
                        />
                        <DatePicker
                          className="form-input"
                          selected={new Date(dateToInitiative)}
                          value={new Date(dateToInitiative)}
                          onChange={handleDateToOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{t('image_main')}</label>
                    <div className="add-photo-form">
                      <div className="add-photo-form-column">
                        {!photo && (
                          <div className="form-file-upload">
                            <div
                              onDragEnter={handleDrag}
                              onSubmit={(e) => e.preventDefault()}
                            >
                              <input
                                ref={inputRef}
                                type="file"
                                className="input-file-upload"
                                multiple={true}
                                onChange={handleChange}
                                accept={'image/png, image/jpg'}
                              />
                              <label
                                htmlFor="input-file-upload"
                                className={
                                  dragActive
                                    ? 'drag-active label-file-upload'
                                    : 'label-file-upload'
                                }
                              >
                                <img
                                  src={uploadPhotoImage}
                                  className="upload-image"
                                  alt="upload-icon"
                                />
                                <p>{uploadError || (file && file.name)}</p>
                              </label>
                              {dragActive && (
                                <div
                                  className="drag-file-element"
                                  onDragEnter={handleDrag}
                                  onDragLeave={handleDrag}
                                  onDragOver={handleDrag}
                                  onDrop={handleDrop}
                                ></div>
                              )}
                            </div>
                          </div>
                        )}
                        {photo && (
                          <>
                            <img
                              src={photo}
                              alt="initiative"
                              className="initiative-photo"
                            />
                            <button
                              className="button-delete-photo"
                              onClick={onDeleteImageButtonClick}
                            >
                              <img
                                src={deletePhotoIcon}
                                className="delete-photo-icon"
                                alt="delete-icon"
                              />
                            </button>
                            <input
                              ref={inputRef}
                              type="file"
                              className="hidden"
                              multiple={true}
                              onChange={handleChange}
                              accept={'image/png, image/jpg'}
                            />
                          </>
                        )}
                      </div>
                      <div className="add-photo-form-column">
                        <p className="description">{t('image_requirements')}</p>
                        <button
                          className="button-upload-photo"
                          onClick={onUploadImageButtonClick}
                        >
                          <img
                            src={cameraIcon}
                            className="camera-icon"
                            alt="camera-icon"
                          />
                          {photo ? t('image_change') : t('image_pick')}
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    className="button-white-green button-preview-modal"
                    onClick={onShowPreviewModalButtonClick}
                  >
                    {t('image_preview')}
                  </button>
                </div>
              </form>
            </div>
            <div className="edit-initiative-actions">
              {!isActive && (
                <button
                  className="button-delete-initiative"
                  onClick={onDeleteInitiativeClick}
                >
                  {t('initiative_delete')}
                </button>
              )}
              {!isActive && (
                <button
                  className="button-green"
                  onClick={onLaunchInitiativeClick}
                >
                  {t('initiative_start')}
                </button>
              )}
              <button onClick={onSaveInitiativeClick} className="button-violet">
                {t('save')}
              </button>
            </div>
          </div>
        </div>
        {showMobileAppPreviewModal && (
          <MobileAppEditPreviewModal initiative={initiative} />
        )}
        {showDeleteInitiativeModal && <DeleteInitiativeModal />}
        {showDeleteGroupModal && <DeleteGroupModal />}
        <div className="section section">
          <div className="container"></div>
        </div>
      </>
    )
  );
};

export default EditInititativeScreen;
