import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from '../hooks/hooks';
import { selectIsUserLogged } from '../redux/authSlice';

const RequireAuth = () => {
  const location = useLocation();
  const isUserLogged = useAppSelector(selectIsUserLogged);

  return isUserLogged ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
