import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Paginate from './Paginate';
import { useAppSelector } from '../hooks/hooks';
import { getInitiativeStatistics } from '../api/statistics';
import { getInitiativeGroupsRanking } from '../api/ranking';
import { selectInitiatives } from '../redux/initiativesSlice';
import { Initiative } from '../types/initiatives';
import { StatisticsData } from '../types/statistics';
import { RankingData } from '../types/ranking';
import { dateTo, dateFrom, formatISODate } from '../helpers/dates';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import calendarIcon from '../static/icons/calendar.svg';
import DatePicker from './DatePicker';

const StatisticsInitiativeScreen = (props: { initiativeTitle?: boolean }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const initiativeTitle = props.initiativeTitle;

  const initiatives = useAppSelector(selectInitiatives);
  const initiative = initiatives
    .filter((initiative: Initiative) => initiative.id === id)
    .pop();

  const [statistics, setStatisticsData] = useState<StatisticsData[]>([]);
  const [statisticsSum, setStatisticsSumData] = useState<StatisticsData[]>([]);
  const [ranking, setRankingData] = useState<RankingData[]>();
  const [from, setDateFrom] = useState<string>(dateFrom);
  const [to, setDateTo] = useState<string>(dateTo);
  const [valueFrom, setValueFrom] = useState<any>(dateFrom);
  const [valueTo, setValueTo] = useState<any>(dateTo);

  useEffect(() => {
    setValueFrom(`${initiative?.dateFrom}`);
    setDateFrom(`${initiative?.dateFrom}`);
    setValueTo(`${initiative?.dateTo}`);
    setDateTo(`${initiative?.dateTo}`);
  }, [initiative]);

  const handleDateFromOnChange = (value: string) => {
    setDateFrom(formatISODate(value));
    setValueFrom(value);
  };

  const handleDateToOnChange = (value: string) => {
    setDateTo(formatISODate(value));
    setValueTo(value);
  };

  useEffect(() => {
    (async () => {
      const statisticsData: StatisticsData[] = await getInitiativeStatistics(
        id!,
        {
          from,
          to,
        }
      );
      let sum = 0;
      const mappedData = statisticsData.map((el) => {
        return { ...el, value: (sum += el.value) };
      });
      setStatisticsData(statisticsData);
      setStatisticsSumData(mappedData);
    })();
  }, [id, from, to]);

  useEffect(() => {
    (async () => {
      const rankingData: RankingData[] = await getInitiativeGroupsRanking(id!, {
        from,
        to,
      });
      const mappedData = rankingData.map((el, index) => {
        return { ...el, index: index + 1 };
      });
      setRankingData(mappedData);
    })();
  }, [id, from, to]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          boxHeight: 0,
          boxWidth: 20,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#ffffff',
        borderColor: 'rgba(0, 0, 0, 0.5)',
        borderWidth: 0.5,
        cornerRadius: 6,
        titleColor: '#707070',
        titleFont: { weight: 'normal' },
        displayColors: false,
        caretSize: 10,
        bodyFont: { size: 14, weight: 'bold' },
        bodyColor: '#809CFF',
        callbacks: {
          label: (tooltipItem: any) => {
            return tooltipItem.formattedValue.replaceAll(',', ' ');
          },
        },
      },
    },
    scales: {
      xAxis: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        title: {
          display: true,
          text: t('chart_days'),
          align: 'end',
        },
      },
      yAxis: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        title: {
          display: true,
          text: t('chart_steps'),
          align: 'end',
        },
      },
    },
    interaction: { intersect: false },
    elements: {
      point: {
        borderWidth: 0,
        radius: 0,
      },
    },
  };

  const data = {
    labels: statistics?.map((el) => {
      return el.title;
    }),
    datasets: [
      {
        label: t('chart_steps_amount'),
        data: statistics?.map((el) => el.value),
        borderColor: '#809CFF',
        fill: false,
        borderWidth: 3,
      },
    ],
  };

  const dataSum = {
    labels: statisticsSum?.map((el) => {
      return el.title;
    }),
    datasets: [
      {
        label: t('chart_steps_sum_amount'),
        data: statisticsSum?.map((el) => el.value),
        borderColor: '#809CFF',
        fill: false,
        borderWidth: 3,
      },
    ],
  };

  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalElements = ranking && ranking.length;
  const elementsPerPage = 6;

  const [filteredRankingElements, setFilteredRankingElements] =
    useState(ranking);

  const indexOfLastElement = currentPage * elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;

  useEffect(() => {
    ranking &&
      ranking.length &&
      setFilteredRankingElements(
        ranking!.slice(indexOfFirstElement, indexOfLastElement)
      );
  }, [indexOfFirstElement, indexOfLastElement, ranking]);

  return (
    <div className="section">
      <div className="container">
        <p className="section-title">
          {t('initiative_statistics')}
          {initiativeTitle !== false && (
            <span>: {initiative && initiative.title}</span>
          )}
        </p>
        <div className="statistics-container">
          <div className="form-group-row">
            <p className="label"> {t('initiative_time')}</p>
            <div>
              {valueFrom && (
                <DatePicker
                  className="form-input"
                  selected={new Date(valueFrom)}
                  onChange={handleDateFromOnChange}
                  value={new Date(valueFrom)}
                />
              )}
              <img
                src={calendarIcon}
                alt="calendar-icon"
                className="calendar-icon-statistics"
              />
            </div>
            <div>
              {valueTo && (
                <DatePicker
                  className="form-input"
                  selected={new Date(valueTo)}
                  onChange={handleDateToOnChange}
                  value={new Date(valueTo)}
                />
              )}
              <img
                src={calendarIcon}
                alt="calendar-icon"
                className="calendar-icon-statistics"
              />
            </div>
          </div>
          <div className="statistics-elements">
            <div className="ranking">
              <div className="ranking-row">
                <p className="title">{t('initiative_ranking')}</p>
                <p className="label-avg">{t('initiative_average_steps')}</p>
                <p className="steps">{t('initiative_summary_steps')}</p>
              </div>
              <ol className="ranking-list initiative-ranking">
                {filteredRankingElements &&
                  filteredRankingElements.map((el, i) => {
                    return (
                      <li
                        key={i}
                        onClick={() => navigate(`/statistics/group/${el.id}`)}
                        className="ranking-link"
                      >
                        <div
                          className={
                            (el.index! === 1 && 'avatar-first-violet') ||
                            (el.index! === 2 && 'avatar-second-violet') ||
                            (el.index! === 3 && 'avatar-third-violet') ||
                            'avatar'
                          }
                        >
                          <p
                            className={
                              ((el.index! === 1 ||
                                el.index! === 2 ||
                                el.index! === 3) &&
                                'avatar-inner-top') ||
                              'avata-inner'
                            }
                          >
                            {el.index}
                          </p>
                        </div>
                        <p className={el.index! <= 3 ? 'top-name' : 'name'}>
                          {el.title}
                        </p>
                        <p className="value-avg">
                          {el.average.toLocaleString().replaceAll(',', ' ')}
                        </p>
                        <p className="value-sum">
                          {el.value.toLocaleString().replaceAll(',', ' ')}
                        </p>
                      </li>
                    );
                  })}
              </ol>
              <Paginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalElements={totalElements!}
                postPerPage={elementsPerPage}
                color={'violet'}
              />
            </div>
          </div>
          <div className="statistics-elements">
            <div className="chart">
              <div className="chart-row">
                <p className="chart-title">
                  {t('initiative_summary_steps_teams')}
                </p>
              </div>
              {/* 
              // @ts-ignore */}
              <Line options={options} data={data} />
            </div>
            <div className="chart">
              <div className="chart-row">
                <p className="chart-title">
                  {t('initiative_average_summary_steps_teams')}
                </p>
              </div>
              {/* 
              // @ts-ignore */}
              <Line options={options} data={dataSum} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsInitiativeScreen;
