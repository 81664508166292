import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../hooks/hooks';
import { selectInitialFlowCurrentStep } from '../redux/initialFlowSlice';
import completeIcon from '../static/icons/complete.svg';

const InitialFlowStepper = () => {
  const currentStep = useAppSelector(selectInitialFlowCurrentStep);
  const { t } = useTranslation();

  const getStepState = (step: number) => {
    return currentStep === step
      ? 'active'
      : currentStep > step
      ? 'done'
      : 'waiting';
  };

  return (
    <div className="initial-flow-stepper-section">
      <div className="container">
        <div className="initial-flow-stepper">
          <div className={`step-${getStepState(1)}`}>
            <Link to={'/employees/add'} className="initial-flow-stepper-link">
              <p className="step-title">{t('step')}</p>
              {getStepState(1) === 'done' ? (
                <img
                  src={completeIcon}
                  alt="complete-icon"
                  className="complete-icon"
                />
              ) : (
                <div className="step-number">1</div>
              )}
              <p className="step-description">{t('dashboard_add_employers')}</p>
            </Link>
            <div className="step-bar-right"></div>
          </div>
          <div className={`step-${getStepState(2)}`}>
            <Link
              to={'/initiatives/create-new'}
              className="initial-flow-stepper-link"
            >
              <p className="step-title">{t('step')}</p>
              {getStepState(2) === 'done' ? (
                <img src={completeIcon} alt="complete-icon" />
              ) : (
                <div className="step-number">2</div>
              )}
              <p className="step-description">{t('initiative_create')}</p>
            </Link>
            <div className="step-bar-left"></div>
            <div className="step-bar-right"></div>
          </div>
          <div className={`step-${getStepState(3)}`}>
            <Link
              to={'/initiatives/create-new/groups/add-manual'}
              className="initial-flow-stepper-link"
            >
              <p className="step-title">{t('step')}</p>
              {getStepState(3) === 'done' ? (
                <img src={completeIcon} alt="complete-icon" />
              ) : (
                <div className="step-number">3</div>
              )}
              <p className="step-description">{t('team_company_create')}</p>
            </Link>
            <div className="step-bar-left"></div>
            <div className="step-bar-right"></div>
          </div>
          <div className={`step-${getStepState(4)}`}>
            <Link
              to={'/initiatives/create-new/confirm'}
              className="initial-flow-stepper-link"
            >
              <p className="step-title">{t('step')}</p>
              {getStepState(4) === 'done' ? (
                <img src={completeIcon} alt="complete-icon" />
              ) : (
                <div className="step-number">4</div>
              )}
              <p className="step-description">{t('initiative_start')}</p>
            </Link>
            <div className="step-bar-left"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitialFlowStepper;
