import qs from 'qs';
import httpApi from './api';
import { UserLoginData, LoginResponse } from '../types/auth';

export const login = async (data: UserLoginData): Promise<LoginResponse> => {
  return await httpApi
    .post('/v1/hr/login', qs.stringify(data))
    .then((res) => res.data.data);
};

export const logout = (): Promise<void> => {
  return httpApi.post('/v1/hr/logout');
};
