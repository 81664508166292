import React from 'react';
import AppContainer from './components/AppContainer';
import './css/App.css';

function App() {
  return (
    <div className="App">
      <AppContainer />
    </div>
  );
}

export default App;
