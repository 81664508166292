import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';

export interface SuccessState {
  isSuccess: boolean;
  successMessage: string;
}

const initialState: SuccessState = {
  isSuccess: false,
  successMessage: '',
};

export const successSlice = createSlice({
  name: 'success',
  initialState,
  reducers: {
    setSuccess: (state, action: PayloadAction<string>) => {
      state.isSuccess = true;
      state.successMessage = action.payload;
    },
    resetSuccess: (state) => {
      state.isSuccess = false;
      state.successMessage = '';
    },
  },
});

export const { setSuccess, resetSuccess } = successSlice.actions;

export const selectSuccess = (state: RootState) => state.success.successMessage;

export default successSlice.reducer;
