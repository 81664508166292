import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InitialFlowStepper from './InitialFlowStepper';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../hooks/hooks';
import { enableInitialFlow, setCurrentStep } from '../redux/initialFlowSlice';
import addEmployeesIcon from '../static/icons/add-employees-white.svg';

const AddEmployeesInitialScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setCurrentStep(1));
  }, [dispatch]);

  const onAddUsersClick = () => {
    dispatch(enableInitialFlow());
    return navigate('/employees/add');
  };

  return (
    <>
      <InitialFlowStepper />
      <div className="section">
        <div className="container">
          <div className="add-initial-employees">
            <button
              className="button-add-initial-employees"
              onClick={onAddUsersClick}
            >
              <img
                src={addEmployeesIcon}
                alt="add-employees-icon"
                className="add-employees-icon"
              />{' '}
              {t('dashboard_add_employers')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEmployeesInitialScreen;
