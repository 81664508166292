import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import { Group } from '../types/groups';
import { Employee } from '../types/employee';

export interface GroupsState {
  data: Group;
}

const initialState: GroupsState = {
  data: { id: '', title: '', users: [] },
};

export const groupEditSlice = createSlice({
  name: 'groupEdit',
  initialState,
  reducers: {
    setEditedGroup: (state, action: PayloadAction<Group>) => {
      state.data = action.payload;
    },
    setNewTitle: (state, action: PayloadAction<{ title: string }>) => {
      state.data.title = action.payload.title;
    },
    addNewUser: (state, action: PayloadAction<{ user: Employee }>) => {
      state.data.users = [...state.data.users, action.payload.user];
    },
    removeExistingUser: (
      state,
      action: PayloadAction<{ id?: number; user: Employee }>
    ) => {
      const users = [...state.data.users];
      state.data.users = users.filter(
        (user) => user.id !== action.payload.user.id
      );
    },
    setInitialEditGroup: (state) => {
      state.data = {
        id: '',
        title: 'Nowa drużyna',
        users: [],
      };
    },
  },
});

export const {
  setEditedGroup,
  setNewTitle,
  addNewUser,
  removeExistingUser,
  setInitialEditGroup,
} = groupEditSlice.actions;

export const selectEditedGroup = (state: RootState) => state.groupEdit.data;

export const selectUsersWithoutGroup = (state: RootState) => {
  let usersInGroupsArray: any = [];
  state.initiatives.groups.map((group) => usersInGroupsArray.push(group.users));
  const usersInGroups = usersInGroupsArray.flat();
  const employees = state.employees.data;

  const usersWithoutGroup = employees.filter((employee) =>
    usersInGroups.every((user: Employee) => !(user.email === employee.email))
  );

  return usersWithoutGroup;
};

export default groupEditSlice.reducer;
