import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { closeMobileAppPreviewModal } from '../redux/modalsSlice';
import {
  selectNewInitiativeTitle,
  selectNewInitiativeDescription,
  selectNewInitiativeGoal,
  selectNewInitiativePhotoUrl,
} from '../redux/initiativeCreationSlice';
import mobileAppPreviewImage from '../static/images/mobile-app-preview.svg';
import mobileAppBackButton from '../static/images/mobile-app-back-button.svg';
import closeIcon from '../static/icons/close.svg';

const MobileAppPreviewModal = () => {
  const { t } = useTranslation();
  const title =
    useAppSelector(selectNewInitiativeTitle) || t('mobile_preview_mock8');
  const description =
    useAppSelector(selectNewInitiativeDescription) || t('mobile_preview_mock9');
  const goal =
    useAppSelector(selectNewInitiativeGoal) || t('mobile_preview_mock10');
  const photo = useAppSelector(selectNewInitiativePhotoUrl);

  const dispatch = useAppDispatch();

  const onCloseModalClick = () => {
    dispatch(closeMobileAppPreviewModal());
  };

  return (
    <div className="dark-background">
      <div className="centered">
        <div className="modal preview-modal">
          <div className="modal-header preview-modal-header">
            <button onClick={onCloseModalClick}>
              <img src={closeIcon} alt="close-icon" className="close-icon" />
            </button>
          </div>
          <div className="preview-modal-body">
            <div className="indicators">
              <p className="title">{t('mobile_preview_header')}</p>
              <p className="editable photo">{t('mobile_preview_main_image')}</p>
              <div className="preview-bar-photo"></div>
              <p className="editable name">{t('mobile_preview_initiative')}</p>
              <div className="preview-bar-name"></div>
              <p className="data progress">{t('mobile_preview_progress')}</p>
              <div className="preview-bar-progress"></div>
              <p className="data users">{t('mobile_preview_users_number')}</p>
              <div className="preview-bar-users"></div>
              <p className="editable goal">{t('mobile_preview_goal')}</p>
              <div className="preview-bar-goal"></div>
              <p className="editable description">
                {t('mobile_preview_goal_description')}
              </p>
              <div className="preview-bar-description"></div>
              <p className="info">{t('mobile_preview_edition')}</p>
            </div>
            <div className="mobile-app-preview">
              <img
                src={mobileAppPreviewImage}
                alt="mobile-app-preview"
                className="mobile-app-preview-image"
              />
              <img
                src={mobileAppBackButton}
                alt="mobile-app-back-button"
                className="mobile-app-back-button"
              />
              {photo ? (
                <img src={photo} alt="initiative" className="photo" />
              ) : (
                <div className="photo-placeholder"></div>
              )}
              <p className="title">
                {title.length > 50 ? `${title.slice(0, 47)}...` : title}
              </p>
              <div className="initiative-data">
                <div className="initiative-type">
                  <p className="action">{t('mobile_preview_mock3')}</p>
                  <p className="type">{t('mobile_preview_mock4')}</p>
                </div>
                <div className="users">
                  <p className="label">{t('mobile_preview_mock5')}</p>
                  <p className="count">134</p>
                </div>
              </div>
              <div className="progressbar-container">
                <div
                  style={{
                    width: '50%',
                  }}
                  className="progressbar-filler"
                ></div>
              </div>
              <div className="progress-data">
                <p className="label-progress">{t('mobile_preview_mock1')}</p>
                <p className="label-month">{t('mobile_preview_mock2')}</p>
              </div>
              <p className="goal-title">{t('mobile_preview_mock6')}</p>
              <p className="goal">
                {goal.length > 78 ? `${goal.slice(0, 75)}...` : goal}
              </p>
              <p className="description-title">{t('mobile_preview_mock7')}</p>
              <p className="description">
                {description.length > 75
                  ? `${description.slice(0, 71)}...`
                  : description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileAppPreviewModal;
