import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InitialFlowStepper from './InitialFlowStepper';
import InitialFlowFooter from './InitialFlowFooter';
import EditGroupCreationModal from './EditGroupCreationModal';
import DeleteGroupCreationModal from './DeleteGroupCreationModal';
import GroupCreation from './GroupCreation';
import {
  getShuffledGroupsByGroupSize,
  getShuffledGroupsByGroupNumber,
} from '../api/groups';
import { CreateGroup } from '../types/groups';
import { useAppSelector, useAppDispatch } from '../hooks/hooks';
import {
  setInitialGroups,
  selectGroups,
  setShuffledGroups,
  selectEditedGroupId,
  addGroup,
  resetInitialGroups,
} from '../redux/groupsCreationSlice';
import {
  selectDisplayGroupModal,
  selectDisplayDeleteGroupModal,
} from '../redux/modalsSlice';
import {
  selectInitialFlowIsActive,
  setCurrentStep,
} from '../redux/initialFlowSlice';
import addAutoGroupIcon from '../static/icons/add-auto-group.svg';
import arrowIcon from '../static/icons/select-arrow.svg';

const CreateNewInitiativeScreen = () => {
  const initialFlow = useAppSelector(selectInitialFlowIsActive);

  const groupsToAdd: CreateGroup[] = useAppSelector(selectGroups);
  const showEditGroupModal = useAppSelector(selectDisplayGroupModal);
  const showDeleteGroupModal = useAppSelector(selectDisplayDeleteGroupModal);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [groupNumberType, setGroupNumberType] = useState<string>('groupsSize');
  const [groupNumber, setGroupsNumber] = useState<any>('5');
  const [groupCreated, setGroupCreated] = useState<any>(false);

  const editedGroupId: number = useAppSelector(selectEditedGroupId);

  useEffect(() => {
    dispatch(setInitialGroups());
    dispatch(setCurrentStep(3));
  }, [dispatch]);

  const options = [
    { name: t('team_people_number'), value: 'groupsSize' },
    { name: t('team_number'), value: 'groupsNumber' },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (option: any) => () => {
    setGroupNumberType(option.value);
    setIsOpen(false);
    setSelectedOption(option);
  };

  const handleOnShuffleGroupsClick = async () => {
    if (groupNumberType === 'groupsSize') {
      dispatch(resetInitialGroups());
      const response = await getShuffledGroupsByGroupSize(groupNumber);
      const groups = response.map((group, i) => {
        return { ...group, id: i + 1 };
      });
      dispatch(setShuffledGroups(groups));
    }
    if (groupNumberType === 'groupsNumber') {
      dispatch(resetInitialGroups());
      const response = await getShuffledGroupsByGroupNumber(groupNumber);
      const groups = response.map((group, i) => {
        return { ...group, id: i + 1 };
      });
      dispatch(setShuffledGroups(groups));
    }
    setGroupCreated(true);
  };

  const handleOnAddGroupClick = () => {
    dispatch(addGroup({ id: groupsToAdd.length + 1 }));
  };

  return (
    <>
      {initialFlow && <InitialFlowStepper />}
      <div className="section">
        <div className="container">
          <p className="section-title">{t('automatically_team_creation')}</p>
          <div className="create-group-form create-group-shuffled-form">
            <img src={addAutoGroupIcon} alt="add-auto-group" />
            <div className="form-group-select-group-shuffled">
              <label>{t('choose')}</label>
              <div className="select-group-shuffled">
                <div className="dropdown-header" onClick={toggling}>
                  <img
                    src={arrowIcon}
                    alt="arrow-icon"
                    className="arrow-icon"
                  />
                  {selectedOption.name}
                </div>
                {isOpen && (
                  <div className="dropdown-list-container">
                    <ul className="dropdown-list">
                      {options.map((option, i) => (
                        <li
                          className="list-item"
                          onClick={onOptionClicked(option)}
                          key={i}
                        >
                          {option.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="form-group-number">
              <label>{t('enter_number')}</label>
              <input
                type="number"
                min={1}
                className="form-input"
                onChange={(e) => setGroupsNumber(e.target.value)}
                value={groupNumber}
              />
            </div>
            <button
              className="button-violet"
              onClick={handleOnShuffleGroupsClick}
            >
              {t('teams_generation')}
            </button>
          </div>
          <div className="groups">
            {groupsToAdd && (
              <>
                {groupCreated && (
                  <p className="label">
                    {t('teams_created1')} <strong>{groupsToAdd.length}</strong>{' '}
                    {t('teams_created2')}{' '}
                    <strong>{groupsToAdd[0]?.users?.length}</strong>{' '}
                    {t('teams_created3')} <br />
                    {t('teams_created4')}
                  </p>
                )}
                {groupsToAdd.map((groupToAdd) => {
                  return (
                    <div key={groupToAdd.id}>
                      <GroupCreation group={groupToAdd} />
                    </div>
                  );
                })}
                <button
                  className="button-add-group button-violet"
                  onClick={handleOnAddGroupClick}
                >
                  {t('add_team')}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {showEditGroupModal && (
        <EditGroupCreationModal group={groupsToAdd[editedGroupId - 1]} />
      )}
      {showDeleteGroupModal && <DeleteGroupCreationModal />}

      {initialFlow && (
        <InitialFlowFooter
          forwardPath={'/initiatives/create-new/confirm'}
          backPath={'/initiatives/create-new/groups/add'}
        />
      )}
    </>
  );
};

export default CreateNewInitiativeScreen;
