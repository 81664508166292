import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import { launchInitiative } from '../api/initiatives';
// import { calculateProgress } from '../helpers/dates';
import { Initiative, InitiativeStatus } from '../types/initiatives';
import editBlackIcon from '../static/icons/edit-black.svg';
import editGrayIcon from '../static/icons/edit-gray.svg';

// const handleOnLaunchInitiativeClick = async ({ id }: string) => {
//   await launchInitiative(id);
// };

// const LaunchButton = (id: string) => {
//   return (
//     <button
//       className="button-action button-launch"
//       onClick={() => handleOnLaunchInitiativeClick(id)}
//     >
//       Start
//     </button>
//   );
// };

// const FinishButton = () => {
//   return <button className="button-action button-finish">Zakończ</button>;
// };

// const LaunchAgainButton = (id: string) => {
//   return (
//     <button
//       className="button-action button-launch-again"
//       onClick={() => handleOnLaunchInitiativeClick(id)}
//     >
//       Uruchom ponownie
//     </button>
//   );
// };

const InitiativeComponent = ({ initiative }: { initiative: Initiative }) => {
  const { dateFrom, id, status, title } = initiative;
  const { t } = useTranslation();

  const navigate = useNavigate();

  const getStatus = () => {
    const { status } = initiative!;
    if (status === InitiativeStatus.ACTIVE) {
      return t('initiative_active');
    }
    if (status === InitiativeStatus.DRAFT) {
      return t('initiative_waiting');
    }
    if (status === InitiativeStatus.HISTORICAL) {
      return t('initiative_ended');
    }
    return status;
  };

  const isHistorical = status === InitiativeStatus.HISTORICAL;
  const handleOnEditInitiativeClick = () => {
    return navigate(`/initiatives/edit/${id}`);
  };

  const handleOnStatisticsClick = () => {
    return navigate(`/statistics/initiative/${id}`);
  };

  // const getActionButton = () => {
  //   if (status === InitiativeStatus.DRAFT) {
  //     return <LaunchButton id={id} />;
  //   } else if (status === InitiativeStatus.HISTORICAL) {
  //     return <LaunchAgainButton id={id} />;
  //   }
  // };

  return (
    <div className={isHistorical ? 'initiative historical' : 'initiative'}>
      <p className="title">{title}</p>
      {/* <div className="progressbar-container">
        <div
          style={{
            width: `${calculateProgress(dateFrom, dateTo)}%`,
          }}
          className="progressbar-filler"
        ></div>
      </div> */}
      <p>{getStatus()}</p>
      <button className="button-statistics" onClick={handleOnStatisticsClick}>
        {t('statistics')}
      </button>
      <p>{dateFrom.toString()}</p>
      <button className="button-edit" onClick={handleOnEditInitiativeClick}>
        <img
          src={isHistorical ? editGrayIcon : editBlackIcon}
          alt={'editIcon'}
        />
      </button>
      {/* <div>{getActionButton()}</div> */}
    </div>
  );
};

export default InitiativeComponent;
