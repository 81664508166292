export const formatDate = (date: Date) => {
  return date.toISOString().split('T')[0];
};

export const formatISODate = (date: string) => {
  return date.split('T')[0];
};

const currentDate = new Date(); // Today
const preDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000); // From today -30 days
export const dateTo = formatDate(currentDate);
export const dateFrom = formatDate(preDate);

export const calculateTimeLeft = (dateTo: string | Date) => {
  if (+new Date() > +new Date(dateTo)) {
    return '0 dni';
  }
  let diffTime = Math.abs(+new Date() - +new Date(dateTo));
  let days = diffTime / (24 * 60 * 60 * 1000);
  let hours = (days % 1) * 24;
  let minutes = (hours % 1) * 60;
  let secs = (minutes % 1) * 60;
  [days, hours, minutes, secs] = [
    Math.floor(days),
    Math.floor(hours),
    Math.floor(minutes),
    Math.floor(secs),
  ];

  return `${days} dni, ${('0' + hours).slice(-2)}:${('0' + minutes).slice(
    -2
  )}:${('0' + secs).slice(-2)}`;
};

export const calculateProgressDays = (
  dateFrom: string | Date,
  dateTo: string | Date
) => {
  if (+new Date() < +new Date(dateFrom)) {
    return 0;
  }

  if (+new Date() > +new Date(dateTo)) {
    let diffTime = Math.abs(+new Date(dateFrom) - +new Date(dateTo)) + 1;
    let days = diffTime / (24 * 60 * 60 * 1000);
    return Math.ceil(days);
  }

  let diffTime = Math.abs(+new Date(dateFrom) - +new Date());
  let days = diffTime / (24 * 60 * 60 * 1000);

  return Math.ceil(days);
};

export const calculateProgress = (
  dateFrom: string | Date,
  dateTo: string | Date
) => {
  if (+new Date() > +new Date(dateTo)) {
    return 100;
  }

  if (+new Date() < +new Date(dateTo)) {
    return 0;
  }

  let totalDiffTime = Math.abs(+new Date(dateFrom) - +new Date(dateTo));
  let totalDays = totalDiffTime / (24 * 60 * 60 * 1000);

  let diffTime = Math.abs(+new Date(dateFrom) - +new Date());
  let days = diffTime / (24 * 60 * 60 * 1000);

  return (days / totalDays) * 100;
};
