import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import { Employee } from '../types/employee';

export interface UsersState {
  data: Employee[];
}

const initialState: UsersState = {
  data: [{ id: '', name: '', surname: '', email: '' }],
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setInitialUser: (state) => {
      state.data = [
        {
          id: 1,
          name: '',
          surname: '',
          email: '',
        },
      ];
    },
    addUser: (state, action: PayloadAction<Employee>) => {
      state.data = [
        ...state.data,
        {
          id: action.payload.id,
          name: '',
          surname: '',
          email: '',
        },
      ];
    },
    setImportedUsers: (state, action: PayloadAction<Employee[]>) => {
      state.data = action.payload;
    },
  },
});

export const { setInitialUser, addUser, setImportedUsers } = usersSlice.actions;

export const selectUsers = (state: RootState) => state.usersCreation.data;

export default usersSlice.reducer;
