import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import { login, logout } from '../api/auth';
import { getProfileData } from '../api/profile';
import { UserLoginData } from '../types/auth';

export interface AuthState {
  isUserLogged: boolean;
  authToken: string | null;
  status: 'idle' | 'loading' | 'failed';
}

const authToken = localStorage.getItem('authToken') || null;

const initialState: AuthState = {
  isUserLogged: authToken ? true : false,
  authToken,
  status: 'idle',
};

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (user: UserLoginData) => {
    const loginResponse = await login(user);
    localStorage.setItem('authToken', loginResponse.token);
    const userResponse = await getProfileData();
    localStorage.setItem('user', JSON.stringify(userResponse));
    return loginResponse;
  }
);

export const logoutUser = createAsyncThunk('auth/logoutUser', async () => {
  await logout();
  localStorage.clear();
  return (window.location.href = '/login');
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.isUserLogged = true;
        state.authToken = action.payload.token;
      })
      .addCase(loginUser.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(logoutUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = 'idle';
        state.isUserLogged = false;
        state.authToken = null;
      })
      .addCase(logoutUser.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectAuthToken = (state: RootState) => state.auth.authToken;
export const selectIsUserLogged = (state: RootState) => state.auth.isUserLogged;

export default authSlice.reducer;
