import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';

const Paginate = ({
  currentPage,
  setCurrentPage,
  totalElements,
  postPerPage,
  color,
}: {
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  totalElements: number;
  postPerPage: number;
  color: string;
}) => {
  const totalPages = Math.ceil(totalElements / postPerPage);

  let pages = [];

  for (let p = 1; p <= totalPages; p++) {
    pages.push(p);
  }

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages, setCurrentPage]);

  const paginate = ({ selected }: { selected: number }) => {
    setCurrentPage(selected + 1);
  };

  return totalPages > 1 ? (
    <ReactPaginate
      onPageChange={paginate}
      pageCount={totalPages}
      previousLabel={'<<'}
      nextLabel={'>>'}
      containerClassName={'pagination'}
      pageClassName={`page-link page-link-${color}`}
      activeLinkClassName={`active-${color}`}
    />
  ) : null;
};

export default Paginate;
