import qs from 'qs';
import httpApi from './api';
import { NotificationData } from '../types/notification';

export const createNotificationForInitiative = async (
  id: string,
  data: NotificationData
): Promise<void> => {
  return await httpApi
    .post(`/v1/hr/initiatives/${id}/notification`, qs.stringify(data))
    .then((res) => res.data.data);
};

export const createNotificationForGroup = async (
  groupId: string,
  data: NotificationData
): Promise<void> => {
  return await httpApi
    .post(
      `/v1/hr/initiative-groups/${groupId}/notification`,
      qs.stringify(data)
    )
    .then((res) => res.data.data);
};

export const createNotificationForUser = async (
  id: string,
  data: NotificationData
): Promise<void> => {
  return await httpApi
    .post(`/v1/hr/employees/${id}/notification`, qs.stringify(data))
    .then((res) => res.data.data);
};
