import httpApi from './api';
import {
  StatisticsParams,
  StatisticsData,
  StatisticsSummaryData,
} from '../types/statistics';
import { dateTo, dateFrom } from '../helpers/dates';

const defaultParams = {
  from: dateFrom,
  to: dateTo,
};

export const getInitiativeStatistics = async (
  id: string | number,
  params: StatisticsParams = defaultParams
): Promise<StatisticsData[]> => {
  return await httpApi
    .get(`/v1/hr/initiatives/${id}/statistics`, { params })
    .then((res) => res.data.data);
};

export const getGroupStatistics = async (
  id: string | number,
  params: StatisticsParams = defaultParams
): Promise<StatisticsData[]> => {
  return await httpApi
    .get(`/v1/hr/initiative-groups/${id}/statistics`, { params })
    .then((res) => res.data.data);
};

export const getEmployeeStatistics = async (
  id: string | number,
  params: StatisticsParams = defaultParams
): Promise<StatisticsData[]> => {
  return await httpApi
    .get(`/v1/hr/employees/${id}/statistics`, { params })
    .then((res) => res.data.data);
};

export const getStatistics = async (
  params: StatisticsParams = defaultParams
): Promise<StatisticsData[]> => {
  return await httpApi
    .get(`/v1/hr/statistics`, { params })
    .then((res) => res.data.data);
};

export const getStatisticsSummary =
  async (): Promise<StatisticsSummaryData> => {
    return await httpApi
      .get(`/v1/hr/statistics/summary`)
      .then((res) => res.data.data);
  };

export const getActiveUsers = async (
  params: StatisticsParams = defaultParams
): Promise<StatisticsData[]> => {
  return await httpApi
    .get(`/v1/hr/statistics/activeUsers`, { params })
    .then((res) => res.data.data);
};

export const getTotalUsers = async (
  params: StatisticsParams = defaultParams
): Promise<StatisticsData[]> => {
  return await httpApi
    .get(`/v1/hr/statistics/totalUsers`, { params })
    .then((res) => res.data.data);
};
