import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import Paginate from './Paginate';
import { getInitiativeGroup } from '../api/groups';
import { getGroupStatistics } from '../api/statistics';
import { getGroupRanking } from '../api/ranking';
import { Group } from '../types/groups';
import { StatisticsData } from '../types/statistics';
import { RankingData } from '../types/ranking';
import { dateTo, dateFrom, formatISODate } from '../helpers/dates';
import backButton from '../static/images/mobile-app-back-button.svg';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import calendarIcon from '../static/icons/calendar.svg';
import DatePicker from './DatePicker';

const StatisticsGroupScreen = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [group, setGroup] = useState<Group>();

  const [statistics, setStatisticsData] = useState<StatisticsData[]>();
  const [statisticsSum, setStatisticsSumData] = useState<StatisticsData[]>([]);

  const [ranking, setRankingData] = useState<RankingData[]>();

  const [from, setDateFrom] = useState<string>(dateFrom);
  const [to, setDateTo] = useState<string>(dateTo);

  const [valueFrom, setValueFrom] = useState<string>(dateFrom);
  const [valueTo, setValueTo] = useState<string>(dateTo);

  const handleDateFromOnChange = (value: string) => {
    setDateFrom(formatISODate(value));
    setValueFrom(value);
  };

  const handleDateToOnChange = (value: string) => {
    setDateTo(formatISODate(value));
    setValueTo(value);
  };

  useEffect(() => {
    (async () => {
      const groupData: Group = await getInitiativeGroup(id!);
      setGroup(groupData);
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      const statisticsData: StatisticsData[] = await getGroupStatistics(id!, {
        from,
        to,
      });
      let sum = 0;
      const mappedData = statisticsData.map((el) => {
        return { ...el, value: (sum += el.value) };
      });
      setStatisticsData(statisticsData);
      setStatisticsSumData(mappedData);
    })();
  }, [id, from, to]);

  useEffect(() => {
    (async () => {
      const rankingData: RankingData[] = await getGroupRanking(id!, {
        from,
        to,
      });
      const mappedData = rankingData.map((el, index) => {
        return { ...el, index: index + 1 };
      });
      setRankingData(mappedData);
    })();
  }, [id, from, to]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          boxHeight: 0,
          boxWidth: 20,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#ffffff',
        borderColor: 'rgba(0, 0, 0, 0.5)',
        borderWidth: 0.5,
        cornerRadius: 6,
        titleColor: '#707070',
        titleFont: { weight: 'normal' },
        displayColors: false,
        caretSize: 10,
        bodyFont: { size: 14, weight: 'bold' },
        bodyColor: '#809CFF',
        callbacks: {
          label: (tooltipItem: any) => {
            return tooltipItem.formattedValue.replaceAll(',', ' ');
          },
        },
      },
    },
    scales: {
      xAxis: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        title: {
          display: true,
          text: t('chart_days'),
          align: 'end',
        },
      },
      yAxis: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        title: {
          display: true,
          text: t('chart_steps'),
          align: 'end',
        },
      },
    },
    interaction: { intersect: false },
    elements: {
      point: {
        borderWidth: 0,
        radius: 0,
      },
    },
  };

  const data = {
    labels: statistics?.map((el) => {
      return el.title;
    }),
    datasets: [
      {
        label: t('chart_steps_amount'),
        data: statistics?.map((el) => el.value),
        borderColor: '#809CFF',
        fill: false,
        borderWidth: 3,
      },
    ],
  };

  const dataSum = {
    labels: statisticsSum?.map((el) => {
      return el.title;
    }),
    datasets: [
      {
        label: t('chart_steps_sum_amount'),
        data: statisticsSum?.map((el) => el.value),
        borderColor: '#809CFF',
        fill: false,
        borderWidth: 3,
      },
    ],
  };

  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalElements = ranking && ranking.length;
  const elementsPerPage = 6;

  const [filteredRankingElements, setFilteredRankingElements] =
    useState(ranking);

  const indexOfLastElement = currentPage * elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;

  useEffect(() => {
    ranking &&
      ranking.length &&
      setFilteredRankingElements(
        ranking!.slice(indexOfFirstElement, indexOfLastElement)
      );
  }, [indexOfFirstElement, indexOfLastElement, ranking]);

  return (
    <div className="section">
      <div className="container">
        <button className="back-button" onClick={() => navigate(-1)}>
          <img src={backButton} alt="back-button" />
        </button>
        <p className="section-title">
          {t('team_statistics')} <span>{group && group.title}</span>
        </p>
        <div className="statistics-container">
          <div className="form-group-row">
            <p className="label">{t('initiative_time')}</p>
            <div>
              <DatePicker
                className="form-input"
                selected={new Date(valueFrom)}
                onChange={handleDateFromOnChange}
                value={new Date(valueFrom)}
              />
              <img
                src={calendarIcon}
                alt="calendar-icon"
                className="calendar-icon-statistics"
              />
            </div>
            <div>
              <DatePicker
                className="form-input"
                selected={new Date(valueTo)}
                onChange={handleDateToOnChange}
                value={new Date(valueTo)}
              />
              <img
                src={calendarIcon}
                alt="calendar-icon"
                className="calendar-icon-statistics"
              />
            </div>
          </div>
          <div className="statistics-elements">
            <div className="ranking">
              <div className="ranking-row">
                <p className="title">{t('initiative_ranking')}</p>
                <p className="steps">{t('steps')}</p>
              </div>
              <ol className="ranking-list">
                {filteredRankingElements &&
                  filteredRankingElements.map((el, i) => {
                    return (
                      <li key={i}>
                        <div
                          className={
                            (el.index! === 1 && 'avatar-first-violet') ||
                            (el.index! === 2 && 'avatar-second-violet') ||
                            (el.index! === 3 && 'avatar-third-violet') ||
                            'avatar'
                          }
                        >
                          <p
                            className={
                              ((el.index! === 1 ||
                                el.index! === 2 ||
                                el.index! === 3) &&
                                'avatar-inner-top') ||
                              'avata-inner'
                            }
                          >
                            {el.index}
                          </p>
                        </div>
                        <p className={el.index! <= 3 ? 'top-name' : 'name'}>
                          {el.title}
                        </p>
                        <p className="value">
                          {el.value.toLocaleString().replaceAll(',', ' ')}
                        </p>
                      </li>
                    );
                  })}
              </ol>
              <Paginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalElements={totalElements!}
                postPerPage={elementsPerPage}
                color={'violet'}
              />
            </div>
          </div>
          <div className="statistics-elements">
            <div className="chart">
              <div className="chart-row">
                <p className="chart-title">{t('team_employees_steps')}</p>
              </div>
              {/* 
              // @ts-ignore */}
              <Line options={options} data={data} />
            </div>
            <div className="chart">
              <div className="chart-row">
                <p className="chart-title">{t('team_employees_steps_sum')}</p>
              </div>
              {/* 
              // @ts-ignore */}
              <Line options={options} data={dataSum} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsGroupScreen;
