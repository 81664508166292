import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import {
  addNewUser,
  removeExistingUser,
  selectAddedGroup,
  selectUsersWithoutGroup,
} from '../redux/groupAddSlice';
import { selectInitiativeGroups } from '../redux/initiativesSlice';
import { Employee } from '../types/employee';
import { Group } from '../types/groups';
import addVioletIcon from '../static/icons/add-violet.svg';
import removeIcon from '../static/icons/remove.svg';
import addUserGroupIcon from '../static/icons/add-user-group.svg';
import userGroupIcon from '../static/icons/user-group.svg';

const GroupAddedSearchedUser = ({ employee }: { employee: Employee }) => {
  const { name, surname, email } = employee;
  const { t } = useTranslation();

  const editedGroup = useAppSelector(selectAddedGroup);
  const editedGroupUsersEmails = editedGroup.users!.map(
    (user: Employee) => user.email
  );

  const savedGroups = useAppSelector(selectInitiativeGroups);
  const usersWithoutGroup = useAppSelector(selectUsersWithoutGroup);

  const isUserInGroup = editedGroupUsersEmails.includes(employee.email);
  const isUserInOtherGroups = !usersWithoutGroup.includes(employee);

  const userSavedGroup = savedGroups
    .filter((savedGroup: Group) =>
      savedGroup.users
        .map((user: Employee) => user.email)
        .includes(employee.email)
    )
    .pop();

  const dispatch = useAppDispatch();

  const handleOnAddUserClick = () => {
    dispatch(addNewUser({ user: employee }));
  };

  const handleOnRemoveUserClick = () => {
    dispatch(removeExistingUser({ user: employee }));
  };

  return (
    <div className="group-user">
      <div className="icon-name">
        {(isUserInGroup || isUserInOtherGroups) && (
          <img src={userGroupIcon} alt="user-icon" className="user-icon" />
        )}
        {!isUserInGroup && !isUserInOtherGroups && (
          <img src={addUserGroupIcon} alt="user-icon" className="user-icon" />
        )}
        <p className="name">
          {name} {surname}
        </p>
      </div>
      <p className="email">{email}</p>
      {isUserInGroup && (
        <button
          className="button-group-user-remove"
          onClick={handleOnRemoveUserClick}
        >
          {t('remove_from_team')}
          <img src={removeIcon} className="remove-icon" alt="remove-icon" />
        </button>
      )}
      {!isUserInGroup && !isUserInOtherGroups && (
        <button
          className="button-group-user-add"
          onClick={handleOnAddUserClick}
        >
          {t('add_to_team')}
          <img src={addVioletIcon} className="add-icon" alt="add-icon" />
        </button>
      )}
      {isUserInOtherGroups && (
        <p className="other-group-info">{userSavedGroup!.title}</p>
      )}
    </div>
  );
};

export default GroupAddedSearchedUser;
