import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmployeeComponent from './Employee';
import DeleteEmployeeModal from './DeleteEmployeeModal';
import Paginate from './Paginate';
import { useAppSelector, useAppDispatch } from '../hooks/hooks';
import { fetchEmployees, selectEmployees } from '../redux/employeesSlice';
import { selectDisplayDeleteEmployeeModal } from '../redux/modalsSlice';
import { Employee } from '../types/employee';
import magnifierIcon from '../static/icons/magnifier.svg';
import clearIcon from '../static/icons/clear.svg';
import arrowIcon from '../static/icons/select-arrow.svg';

const EmplyeesScreen = () => {
  const employees = useAppSelector(selectEmployees);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const showModal = useAppSelector(selectDisplayDeleteEmployeeModal);

  useEffect(() => {
    dispatch(fetchEmployees());
  }, [dispatch]);

  const [searchInput, setSearchInput] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [elementsPerPage, setElementsPerPage] = useState<number>(5);

  const [filteredEmployees, setFilteredEmployees] =
    useState<Employee[]>(employees);
  const totalElements = employees.length;

  const indexOfLastElement = currentPage * elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;

  const options = [5, 10, 20, 50];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (option: any) => () => {
    setCurrentPage(1);
    setElementsPerPage(option);
    setIsOpen(false);
    setSelectedOption(option);
  };

  useEffect(() => {
    setFilteredEmployees(
      employees.slice(indexOfFirstElement, indexOfLastElement)
    );
  }, [employees, indexOfFirstElement, indexOfLastElement]);

  const handleChangeSearch = (e: any) => {
    if (e.target.value.length > 0) {
      setCurrentPage(1);
    }
    setSearchInput(e.target.value);
    if (e.target.value === '') {
      return setFilteredEmployees(
        employees.slice(indexOfFirstElement, indexOfLastElement)
      );
    }
    const filteredElements = employees.filter(
      (employee: Employee) =>
        `${employee.name.toLowerCase()} ${employee.surname.toLowerCase()}`.includes(
          searchInput.toLowerCase()
        ) || employee.email.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredEmployees(filteredElements);
  };

  const onClearIconClick = () => {
    setSearchInput('');
    setFilteredEmployees(
      employees.slice(indexOfFirstElement, indexOfLastElement)
    );
  };

  return (
    <>
      <div className="section">
        <div className="container">
          <p className="section-title">{t('participants')}</p>
          <div className="employee-list-actions">
            <div className="employee-input-search">
              <label>{t('search_person')}</label>
              <img
                src={magnifierIcon}
                alt="magnifier-icon"
                className="magnifier-icon"
              />
              <input
                type="text"
                value={searchInput}
                onChange={handleChangeSearch}
                className="input-search"
              />
              <button className="button-clear-input" onClick={onClearIconClick}>
                <img
                  src={clearIcon}
                  alt="clear-icon"
                  className={
                    searchInput !== '' ? 'clear-icon' : 'clear-icon hidden'
                  }
                />
              </button>
            </div>
            <div className="employee-select-count">
              <label>{t('show')}</label>
              <div className="select-employees-number" onClick={toggling}>
                <div className="dropdown-header">
                  <img
                    src={arrowIcon}
                    alt="arrow-icon"
                    className="arrow-icon"
                  />
                  {selectedOption}
                </div>
                {isOpen && (
                  <div className="dropdown-list-container">
                    <ul className="dropdown-list">
                      {options.map((option, i) => (
                        <li
                          className="list-item"
                          onClick={onOptionClicked(option)}
                          key={i}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          {filteredEmployees &&
            filteredEmployees.map((employee: Employee) => (
              <EmployeeComponent employee={employee} key={employee.id} />
            ))}
          <Paginate
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalElements={totalElements}
            postPerPage={elementsPerPage}
            color={'green'}
          />
        </div>
      </div>
      {showModal && <DeleteEmployeeModal />}
    </>
  );
};

export default EmplyeesScreen;
