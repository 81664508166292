import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';

export interface ModalsState {
  displayGroupModal: boolean;
  displayNewGroupModal: boolean;
  displayDeleteEmployeeModal: boolean;
  displayDeleteInitiativeModal: boolean;
  displayDeleteGroupModal: boolean;
  displayMobileAppPreviewModal: boolean;
}

const initialState: ModalsState = {
  displayGroupModal: false,
  displayNewGroupModal: false,
  displayDeleteEmployeeModal: false,
  displayDeleteInitiativeModal: false,
  displayDeleteGroupModal: false,
  displayMobileAppPreviewModal: false,
};

export const modalsSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    displayGroupModal: (state) => {
      state.displayGroupModal = true;
    },
    closeGroupModal: (state) => {
      state.displayGroupModal = false;
    },
    displayNewGroupModal: (state) => {
      state.displayNewGroupModal = true;
    },
    closeNewGroupModal: (state) => {
      state.displayNewGroupModal = false;
    },
    displayDeleteEmployeeModal: (state) => {
      state.displayDeleteEmployeeModal = true;
    },
    closeDeleteEmployeeModal: (state) => {
      state.displayDeleteEmployeeModal = false;
    },
    displayDeleteInitiativeModal: (state) => {
      state.displayDeleteInitiativeModal = true;
    },
    closeDeleteInitiativeModal: (state) => {
      state.displayDeleteInitiativeModal = false;
    },
    displayDeleteGroupModal: (state) => {
      state.displayDeleteGroupModal = true;
    },
    closeDeleteGroupModal: (state) => {
      state.displayDeleteGroupModal = false;
    },
    displayMobileAppPreviewModal: (state) => {
      state.displayMobileAppPreviewModal = true;
    },
    closeMobileAppPreviewModal: (state) => {
      state.displayMobileAppPreviewModal = false;
    },
  },
});

export const {
  displayGroupModal,
  closeGroupModal,
  displayNewGroupModal,
  closeNewGroupModal,
  displayDeleteEmployeeModal,
  closeDeleteEmployeeModal,
  displayDeleteInitiativeModal,
  closeDeleteInitiativeModal,
  displayDeleteGroupModal,
  closeDeleteGroupModal,
  displayMobileAppPreviewModal,
  closeMobileAppPreviewModal,
} = modalsSlice.actions;

export const selectDisplayGroupModal = (state: RootState) =>
  state.modals.displayGroupModal;

export const selectDisplayNewGroupModal = (state: RootState) =>
  state.modals.displayNewGroupModal;

export const selectDisplayDeleteEmployeeModal = (state: RootState) =>
  state.modals.displayDeleteEmployeeModal;

export const selectDisplayDeleteInitiativeModal = (state: RootState) =>
  state.modals.displayDeleteInitiativeModal;

export const selectDisplayDeleteGroupModal = (state: RootState) =>
  state.modals.displayDeleteGroupModal;

export const selectDisplayMobileAppPreviewModal = (state: RootState) =>
  state.modals.displayMobileAppPreviewModal;

export default modalsSlice.reducer;
