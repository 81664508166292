import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GoBackIcon from '../static/icons/go-back.svg';
import GoForwardIcon from '../static/icons/go-forward.svg';

const InitialFlowFooter = ({
  forwardPath,
  backPath,
}: {
  forwardPath: string;
  backPath: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOnBackClick = () => {
    navigate(backPath);
  };

  const handleOnForwardClick = () => {
    navigate(forwardPath);
  };

  return (
    <>
      <div className="initial-flow-footer-spacer"></div>
      <div className="initial-flow-footer">
        <button onClick={handleOnBackClick} className="button-go-back">
          <img src={GoBackIcon} alt="go-back-icon" className="go-back-icon" />
          {t('back')}
        </button>
        <button onClick={handleOnForwardClick} className="button-go-forward">
          {t('forward')}
          <img
            src={GoForwardIcon}
            alt="go-forward-icon"
            className="go-forward-icon"
          />
        </button>
      </div>
    </>
  );
};

export default InitialFlowFooter;
