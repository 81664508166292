import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import entranslation from './locales/en.json';
import pltranslation from './locales/pl.json';

const resources = {
  en: {
    translation: entranslation,
  },
  pl: {
    translation: pltranslation,
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
});

export default i18n;
