import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../hooks/hooks';
import { setSuccess, resetSuccess } from '../redux/successSlice';
import { forgotPasswordInitialize } from '../api/forgotPassword';
import { ForgotPasswordData } from '../types/forgotPassword';
import GoForwardIcon from '../static/icons/go-forward.svg';

const ForgotPasswordScreen = () => {
  const { register, handleSubmit } = useForm<ForgotPasswordData>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const submitForm = async (data: ForgotPasswordData) => {
    try {
      await forgotPasswordInitialize(data);
      dispatch(setSuccess('Wysłaliśmy instrukcję na podany adres e-mail'));
      setTimeout(() => dispatch(resetSuccess()), 4000);
    } catch {
      //
    }
  };

  return (
    <div className="login-screen">
      <div className="login-form">
        <p className="header">{t('reset_password')}</p>
        <p className="label">{t('reset_password_email')}</p>
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="form-group">
            <label>{t('login_email')}</label>
            <input type="email" className="form-input" {...register('email')} />
          </div>
          <div className="login-form-row">
            <Link to={'/login'} className="reset-password-link">
              {t('reset_back_to_login')}
            </Link>
            <button type="submit" className="button-submit-reset-password">
              {t('reset_send_instruction')}
              <img
                src={GoForwardIcon}
                alt="go-forward-icon"
                className="go-forward-icon"
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordScreen;
