import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import errorReducer from './errorSlice';
import successReducer from './successSlice';
import modalsReducer from './modalsSlice';
import initiativesReducer from './initiativesSlice';
import initiativeCreationReducer from './initiativeCreationSlice';
import groupsCreationReducer from './groupsCreationSlice';
import groupEditReducer from './groupEditSlice';
import groupAddReducer from './groupAddSlice';
import usersReducer from './usersSlice';
import employeesReducer from './employeesSlice';
import initialFlowReducer from './initialFlowSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    error: errorReducer,
    success: successReducer,
    modals: modalsReducer,
    initiatives: initiativesReducer,
    initiativeCreation: initiativeCreationReducer,
    groupsCreation: groupsCreationReducer,
    groupEdit: groupEditReducer,
    groupAdd: groupAddReducer,
    usersCreation: usersReducer,
    employees: employeesReducer,
    initialFlow: initialFlowReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
