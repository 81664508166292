import React, {
  useEffect,
  useState,
  useRef,
  MouseEvent,
  DragEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import EditGroupCreationModal from './EditGroupCreationModal';
import DeleteGroupCreationModal from './DeleteGroupCreationModal';
import GroupCreation from './GroupCreation';
import InitialFlowStepper from './InitialFlowStepper';
import MobileAppPreviewModal from './MobileAppPreviewModal';
import { createInitiative, launchInitiative } from '../api/initiatives';
import { addGroupToInitiative } from '../api/groups';
import { uploadPhoto } from '../api/photos';
import { CreateInitiative } from '../types/initiatives';
import { CreateGroup } from '../types/groups';
import { useAppSelector, useAppDispatch } from '../hooks/hooks';
import {
  selectGroups,
  selectEditedGroupId,
  addGroup,
  resetInitialGroups,
} from '../redux/groupsCreationSlice';
import {
  selectDisplayGroupModal,
  selectDisplayDeleteGroupModal,
  selectDisplayMobileAppPreviewModal,
  displayMobileAppPreviewModal,
} from '../redux/modalsSlice';
import {
  selectInitialFlowIsActive,
  setCurrentStep,
} from '../redux/initialFlowSlice';
import {
  setTitle,
  setDescription,
  setGoal,
  setPhotoUrl,
  resetInitialInitiative,
  selectNewInitiativeTitle,
  selectNewInitiativeDescription,
  selectNewInitiativeGoal,
  selectNewInitiativeDateFrom,
  selectNewInitiativeDateTo,
  selectNewInitiativePhotoUrl,
} from '../redux/initiativeCreationSlice';
import uploadPhotoImage from '../static/images/upload-photo.svg';
import deletePhotoIcon from '../static/icons/bin-white.svg';
import cameraIcon from '../static/icons/camera.svg';
import createInitiativeIcon from '../static/icons/create-initiative.svg';
import editIcon from '../static/icons/edit-gray.svg';
import DatePicker from './DatePicker';
import { formatDate, formatISODate } from '../helpers/dates';

const CreateNewInitiativeConfirmScreen = () => {
  const initialFlow = useAppSelector(selectInitialFlowIsActive);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setCurrentStep(4));
  }, [dispatch]);

  const groupsToAdd: CreateGroup[] = useAppSelector(selectGroups);
  const showEditGroupModal = useAppSelector(selectDisplayGroupModal);
  const showDeleteGroupModal = useAppSelector(selectDisplayDeleteGroupModal);
  const showMobileAppPreviewModal = useAppSelector(
    selectDisplayMobileAppPreviewModal
  );

  const [file, setFile] = useState<any>(null);
  const [uploadError, setUploadError] = useState<string>('');

  const title = useAppSelector(selectNewInitiativeTitle);
  const description = useAppSelector(selectNewInitiativeDescription);
  const goal = useAppSelector(selectNewInitiativeGoal);
  const dateFrom = useAppSelector(selectNewInitiativeDateFrom);
  const dateTo = useAppSelector(selectNewInitiativeDateTo);
  const photo = useAppSelector(selectNewInitiativePhotoUrl);

  const [titleError, setTitleError] = useState<boolean>(false);
  const [descriptionError, setDescriptionError] = useState<boolean>(false);
  const [goalError, setGoalError] = useState<boolean>(false);
  const [titleToShortError, setTitleToShortError] = useState<boolean>(false);
  const [descriptionToShortError, setDescriptionToShortError] =
    useState<boolean>(false);
  const [goalToShortError, setGoalToShortError] = useState<boolean>(false);

  const [dateFromInitiative, setDateFromInitiative] = useState<string>(
    dateFrom || `${formatDate(new Date())}`
  );
  const [dateToInitiative, setDateToInitiative] = useState<string>(
    dateTo || `${formatDate(new Date())}`
  );

  const errorWrongFormat = t('error_wrong_format');
  const errorTooLargeFile = t('error_too_large_file');
  const errorWrongDimensions = t('error_wrong_dimensions');

  const onTitleChange = (value: string) => {
    dispatch(setTitle(value));
    if (titleError || titleToShortError) {
      value.length < 255 ? setTitleError(false) : setTitleError(true);
      value.length > 5
        ? setTitleToShortError(false)
        : setTitleToShortError(true);
    }
  };

  const onTitleBlur = (value: string) => {
    value.length < 255 ? setTitleError(false) : setTitleError(true);
    value.length > 5 ? setTitleToShortError(false) : setTitleToShortError(true);
  };

  const onDescriptionChange = (value: string) => {
    dispatch(setDescription(value));
    if (descriptionError || descriptionToShortError) {
      value.length < 2000
        ? setDescriptionError(false)
        : setDescriptionError(true);
      value.length > 5
        ? setDescriptionToShortError(false)
        : setDescriptionToShortError(true);
    }
  };

  const onDescriptionBlur = (value: string) => {
    value.length < 2000
      ? setDescriptionError(false)
      : setDescriptionError(true);
    value.length > 5
      ? setDescriptionToShortError(false)
      : setDescriptionToShortError(true);
  };

  const onGoalChange = (value: string) => {
    dispatch(setGoal(value));
    if (goalError || goalToShortError) {
      value.length < 500 ? setGoalError(false) : setGoalError(true);
      value.length > 5 ? setGoalToShortError(false) : setGoalToShortError(true);
    }
  };

  const onGoalBlur = (value: string) => {
    value.length < 500 ? setGoalError(false) : setGoalError(true);
    value.length > 5 ? setGoalToShortError(false) : setGoalToShortError(true);
  };

  useEffect(() => {
    title.length > 255 && setTitleError(true);
    description.length > 2000 && setDescriptionError(true);
    goal.length > 500 && setGoalError(true);
  }, [description.length, goal.length, title.length]);

  const [dragActive, setDragActive] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    setFile(e.dataTransfer.files[0]);
    e.dataTransfer.files[0] && handleOnSubmitFile(e.dataTransfer.files[0]);
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    e.target.files[0] && handleOnSubmitFile(e.target.files[0]);
  };

  const onUploadImageButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    inputRef.current!.click();
  };

  const onDeleteImageButtonClick = () => {
    dispatch(setPhotoUrl(''));
    setFile(null);
  };

  const handleOnSubmitFile = async (file: any) => {
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      return setUploadError(errorWrongFormat);
    } else if (file.size > 1000000) {
      return setUploadError(errorTooLargeFile);
    }
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.naturalWidth !== 750 || img.naturalHeight !== 362) {
        return setUploadError(errorWrongDimensions);
      }
    };
    const formData: FormData = new FormData();
    formData.append('photo', file);
    const { path } = await uploadPhoto(formData);
    path && dispatch(setPhotoUrl(path));
  };

  const onShowPreviewModalButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(displayMobileAppPreviewModal());
  };

  const editedGroupId: number = useAppSelector(selectEditedGroupId);

  const handleOnAddGroupClick = () => {
    dispatch(addGroup({ id: groupsToAdd.length + 1 }));
  };

  const submitInitiativeForm = async () => {
    const data: CreateInitiative = {
      title,
      description,
      goal,
      dateTo: dateToInitiative,
      dateFrom: dateFromInitiative,
      photo,
      status: 'DRAFT',
    };
    const { id } = await createInitiative(data);
    if (id) {
      for (const group of groupsToAdd) {
        group.users.length && (await addGroupToInitiative(id, group));
      }
    }
    dispatch(resetInitialInitiative());
    dispatch(resetInitialGroups());
    navigate('/dashboard');
  };

  const submitInitiativeFormWithLaunch = async () => {
    const data: CreateInitiative = {
      title,
      description,
      goal,
      dateTo,
      dateFrom,
      photo,
      status: 'DRAFT',
    };
    const { id } = await createInitiative(data);
    if (id) {
      for (const group of groupsToAdd) {
        group.users.length && (await addGroupToInitiative(id, group));
      }
    }
    await launchInitiative(id);
    dispatch(resetInitialInitiative());
    dispatch(resetInitialGroups());
    navigate('/dashboard');
  };

  const handleDateFromOnChange = (date: Date) => {
    const dateFrom = formatISODate(`${date}`);
    setDateFromInitiative(dateFrom);
  };

  const handleDateToOnChange = (date: Date) => {
    const dateTo = formatISODate(`${date}`);
    setDateToInitiative(dateTo);
  };

  return (
    <>
      {initialFlow && <InitialFlowStepper />}
      <div className="section">
        <div className="container">
          <p className="section-title">{t('initiative_start')}</p>
          <p className="create-initative-form-label">{t('last_step')}</p>
        </div>
      </div>
      <div className="section section-white">
        <div className="container section-white">
          <div className="initiative-title-with-icon">
            <img src={createInitiativeIcon} alt="create-initiative-icon" />
            <div>
              <p className="title">{t('add_description')}</p>
              <p className="label">{t('check-description')}</p>
            </div>
          </div>
          <div className="create-initative-form">
            <form>
              <div className="create-initative-form-column">
                <div className="form-group">
                  <label
                    className={
                      titleError || titleToShortError ? 'label-error' : ''
                    }
                  >
                    {t('initiative_title')}
                    {titleError &&
                      ` (${t('error_long_title').toLocaleLowerCase()})`}
                    {titleToShortError &&
                      ` (${t('error_short_title').toLocaleLowerCase()})`}
                  </label>
                  <input
                    type="text"
                    className={
                      titleError || titleToShortError
                        ? 'form-input input-error'
                        : 'form-input'
                    }
                    onChange={(e) => onTitleChange(e.target.value)}
                    onBlur={(e) => onTitleBlur(e.target.value)}
                    value={title}
                  />
                  <img
                    src={editIcon}
                    alt="edit-icon"
                    className="input-edit-icon"
                  />
                </div>
                <div className="form-group">
                  <label
                    className={
                      descriptionError || descriptionToShortError
                        ? 'label-error'
                        : ''
                    }
                  >
                    {t('initiative_description')}
                    {descriptionError &&
                      ` (${t('error_long_description').toLocaleLowerCase()})`}
                    {descriptionToShortError &&
                      ` (${t('error_short_description').toLocaleLowerCase()})`}
                  </label>
                  <textarea
                    className={
                      descriptionError || descriptionToShortError
                        ? 'form-textarea-description input-error'
                        : 'form-textarea-description'
                    }
                    onChange={(e) => onDescriptionChange(e.target.value)}
                    onBlur={(e) => onDescriptionBlur(e.target.value)}
                    value={description}
                  />
                  <img
                    src={editIcon}
                    alt="edit-icon"
                    className="input-edit-icon"
                  />
                </div>
                <div className="form-group">
                  <label
                    className={
                      goalError || goalToShortError ? 'label-error' : ''
                    }
                  >
                    {t('initiative_goal')}
                    {goalError &&
                      ` (${t('error_long_goal').toLocaleLowerCase()})`}
                    {goalToShortError &&
                      ` (${t('error_short_goal').toLocaleLowerCase()})`}
                  </label>
                  <textarea
                    className={
                      goalError || goalToShortError
                        ? 'form-textarea-goal input-error'
                        : 'form-textarea-goal'
                    }
                    onChange={(e) => onGoalChange(e.target.value)}
                    onBlur={(e) => onGoalBlur(e.target.value)}
                    value={goal}
                  />
                  <img
                    src={editIcon}
                    alt="edit-icon"
                    className="input-edit-icon"
                  />
                </div>
              </div>
              <div className="create-initative-form-column">
                <div className="form-group-row">
                  <div className="form-group">
                    <label>{t('pick_month')}</label>
                    <div className="form-select-range">
                      <DatePicker
                        className="form-input"
                        selected={new Date(dateFromInitiative)}
                        value={new Date(dateFromInitiative)}
                        onChange={handleDateFromOnChange}
                      />
                      <DatePicker
                        className="form-input"
                        selected={new Date(dateToInitiative)}
                        value={new Date(dateToInitiative)}
                        onChange={handleDateToOnChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>{t('image_main')}</label>
                  <div className="add-photo-form">
                    <div className="add-photo-form-column">
                      {!photo && (
                        <div className="form-file-upload">
                          <div
                            onDragEnter={handleDrag}
                            onSubmit={(e) => e.preventDefault()}
                          >
                            <input
                              ref={inputRef}
                              type="file"
                              className="input-file-upload"
                              multiple={true}
                              onChange={handleChange}
                              accept={'image/png, image/jpg'}
                            />
                            <label
                              htmlFor="input-file-upload"
                              className={
                                dragActive
                                  ? 'drag-active label-file-upload'
                                  : 'label-file-upload'
                              }
                            >
                              <img
                                src={uploadPhotoImage}
                                className="upload-image"
                                alt="upload-icon"
                              />
                              <p>{uploadError || (file && file.name)}</p>
                            </label>
                            {dragActive && (
                              <div
                                className="drag-file-element"
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                              ></div>
                            )}
                          </div>
                        </div>
                      )}
                      {photo && (
                        <>
                          <img
                            src={photo}
                            alt="initiative"
                            className="initiative-photo"
                          />
                          <button
                            className="button-delete-photo"
                            onClick={onDeleteImageButtonClick}
                          >
                            <img
                              src={deletePhotoIcon}
                              className="delete-photo-icon"
                              alt="delete-icon"
                            />
                          </button>
                          <input
                            ref={inputRef}
                            type="file"
                            className="hidden"
                            multiple={true}
                            onChange={handleChange}
                            accept={'image/png, image/jpg'}
                          />
                        </>
                      )}
                    </div>
                    <div className="add-photo-form-column">
                      <p className="description">{t('image_requirements')}</p>
                      <button
                        className="button-upload-photo"
                        onClick={onUploadImageButtonClick}
                      >
                        <img
                          src={cameraIcon}
                          className="camera-icon"
                          alt="camera-icon"
                        />
                        {photo ? t('image_change') : t('image_pick')}
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  className="button-white-green button-preview-modal"
                  onClick={onShowPreviewModalButtonClick}
                >
                  {t('image_preview')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <p className="section-title">{t('your_teams')}</p>
          {groupsToAdd && (
            <>
              {groupsToAdd.map((groupToAdd) => {
                return (
                  <div key={groupToAdd.id}>
                    <GroupCreation group={groupToAdd} />
                  </div>
                );
              })}
              <button
                className="button-add-group button-violet"
                onClick={handleOnAddGroupClick}
              >
                {t('add_team')}
              </button>
            </>
          )}
        </div>
      </div>
      {showEditGroupModal && (
        <EditGroupCreationModal group={groupsToAdd[editedGroupId - 1]} />
      )}
      {showDeleteGroupModal && <DeleteGroupCreationModal />}
      {showMobileAppPreviewModal && <MobileAppPreviewModal />}
      {initialFlow && (
        <div className="section">
          <div className="container">
            <div className={'initial-flow-confirm-footer'}>
              <button
                onClick={submitInitiativeForm}
                className="button-save-initiative"
              >
                {t('initiative_save')}
              </button>
              <button
                onClick={submitInitiativeFormWithLaunch}
                className="button-launch-initiative"
              >
                {t('initiative_turn_on')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateNewInitiativeConfirmScreen;
