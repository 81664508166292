import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import { CreateGroup } from '../types/groups';
import { Employee } from '../types/employee';
export interface GroupsState {
  data: CreateGroup[];
  editedGroupId: number;
  editedGroup: CreateGroup;
}

const serializedGroupsCreation = localStorage.getItem('groups-creation');
const groupsCreationData = JSON.parse(serializedGroupsCreation!);

const initialState: GroupsState = {
  data: groupsCreationData || [
    {
      id: 1,
      title: 'Drużyna 1',
      users: [],
    },
    {
      id: 2,
      title: 'Drużyna 2',
      users: [],
    },
    {
      id: 3,
      title: 'Drużyna 3',
      users: [],
    },
    {
      id: 4,
      title: 'Drużyna 4',
      users: [],
    },
    {
      id: 5,
      title: 'Drużyna 5',
      users: [],
    },
  ],
  editedGroupId: 0,
  editedGroup: {
    id: '',
    title: '',
    users: [],
  },
};

export const groupsCreationSlice = createSlice({
  name: 'groupsCreation',
  initialState,
  reducers: {
    setInitialGroups: (state) => {
      state.data = initialState.data;
    },
    addGroup: (state, action: PayloadAction<{ id: number }>) => {
      state.data = [
        ...state.data,
        {
          id: action.payload.id,
          title: `Drużyna ${state.data.length + 1}`,
          users: [],
        },
      ];
      localStorage.setItem('groups-creation', JSON.stringify(state.data));
    },
    removeGroup: (state, action: PayloadAction<{ id: string | number }>) => {
      const groups = [...state.data];
      state.data = groups.filter((group) => group.id !== action.payload.id);
      localStorage.setItem('groups-creation', JSON.stringify(state.data));
    },
    setGroupTitle: (
      state,
      action: PayloadAction<{ id: string | number; title: string }>
    ) => {
      state.data[+action.payload.id - 1].title = action.payload.title;
      localStorage.setItem('groups-creation', JSON.stringify(state.data));
    },
    addUser: (
      state,
      action: PayloadAction<{ id: string | number; user: Employee }>
    ) => {
      state.data[+action.payload.id - 1].users = [
        ...state.data[+action.payload.id - 1].users,
        action.payload.user,
      ];
      localStorage.setItem('groups-creation', JSON.stringify(state.data));
    },
    removeUser: (
      state,
      action: PayloadAction<{ id: string | number; user: Employee }>
    ) => {
      const users = [...state.data[+action.payload.id - 1].users];
      state.data[+action.payload.id - 1].users = users.filter(
        (user) => user.id !== action.payload.user.id
      );
      localStorage.setItem('groups-creation', JSON.stringify(state.data));
    },
    setShuffledGroups: (state, action: PayloadAction<CreateGroup[]>) => {
      state.data = action.payload;
      localStorage.setItem('groups-creation', JSON.stringify(state.data));
    },
    setEditedGroupId: (state, action: PayloadAction<number>) => {
      state.editedGroupId = action.payload;
      localStorage.setItem('groups-creation', JSON.stringify(state.data));
    },
    resetInitialGroups: (state) => {
      localStorage.removeItem('groups-creation');
      state.data = initialState.data;
    },
    setEditedGroup: (state, action: PayloadAction<CreateGroup>) => {
      state.editedGroup = action.payload;
    },
    setEditedGroupTitle: (state, action: PayloadAction<string>) => {
      state.editedGroup.title = action.payload;
    },
    addUserToEditedGroup: (
      state,
      action: PayloadAction<{ id: string | number; user: Employee }>
    ) => {
      state.editedGroup.users = [
        ...state.editedGroup.users,
        action.payload.user,
      ];
    },
    removeUserFromEditedGroup: (
      state,
      action: PayloadAction<{ id: string | number; user: Employee }>
    ) => {
      const users = [...state.editedGroup.users];
      state.editedGroup.users = users.filter(
        (user) => user.id !== action.payload.user.id
      );
    },
    saveEditedGroup: (state, action: PayloadAction<CreateGroup>) => {
      const groups = [...state.data];
      state.data = groups.filter((groups) => groups.id !== action.payload.id);
      state.data = [...state.data, action.payload].sort(
        (a: CreateGroup, b: CreateGroup) => (a.id! > b.id! ? 1 : -1)
      );
    },
    resetEditedGroup: (state) => {
      state.editedGroup = initialState.editedGroup;
    },
  },
});

export const {
  setInitialGroups,
  addGroup,
  removeGroup,
  setGroupTitle,
  addUser,
  removeUser,
  setShuffledGroups,
  setEditedGroupId,
  resetInitialGroups,
  setEditedGroup,
  setEditedGroupTitle,
  addUserToEditedGroup,
  removeUserFromEditedGroup,
  saveEditedGroup,
  resetEditedGroup,
} = groupsCreationSlice.actions;

export const selectGroups = (state: RootState) => state.groupsCreation.data;
export const selectEditedGroupId = (state: RootState) =>
  state.groupsCreation.editedGroupId;
export const selectEditedGroup = (state: RootState) =>
  state.groupsCreation.editedGroup;
export const selectUsersInGroups = (state: RootState) => {
  let usersInGroups: any = [];
  state.groupsCreation.data.map((group) => usersInGroups.push(group.users));
  return usersInGroups.flat();
};
export const selectUsersWithoutGroup = (state: RootState) => {
  const usersInGroupsArray = state.groupsCreation.data.map((group) => {
    const filteredUsers = group.users.filter((item) => {
      return state.groupsCreation.editedGroup.users.find((element) => {
        return element.id === item.id;
      });
    });
    return filteredUsers;
  });
  const usersInGroups = usersInGroupsArray.flat();
  const employees = state.employees.data;

  const usersWithoutGroup = employees.filter((employee) =>
    usersInGroups.every((user: Employee) => !(user.email === employee.email))
  );

  return usersWithoutGroup;
};

export default groupsCreationSlice.reducer;
