import httpApi from './api';
import { RankingParams, RankingData } from '../types/ranking';
import { dateTo, dateFrom } from '../helpers/dates';

const defaultParams = {
  from: dateFrom,
  to: dateTo,
};

export const getInitiativeGroupsRanking = async (
  id: string | number,
  params: RankingParams = defaultParams
): Promise<RankingData[]> => {
  return await httpApi
    .get(`/v1/hr/initiatives/${id}/groups/ranking`, { params })
    .then((res) => res.data.data);
};

export const getGroupRanking = async (
  id: string | number,
  params: RankingParams = defaultParams
): Promise<RankingData[]> => {
  return await httpApi
    .get(`/v1/hr/initiative-groups/${id}/ranking`, { params })
    .then((res) => res.data.data);
};

export const getEmployeesRanking = async (
  params: RankingParams = defaultParams
): Promise<RankingData[]> => {
  return await httpApi
    .get(`/v1/hr/employees/ranking`, { params })
    .then((res) => res.data.data);
};
