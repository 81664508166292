import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { deleteEmployee } from '../api/employees';
import { closeDeleteEmployeeModal } from '../redux/modalsSlice';
import {
  fetchEmployees,
  selectDeletedEmployeeId,
} from '../redux/employeesSlice';
import deleteEmployeeIcon from '../static/icons/delete-employee.svg';
import closeIcon from '../static/icons/close.svg';

const DeleteEmployeeModal = ({
  setAdded,
}: {
  setAdded?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const employeeId = useAppSelector(selectDeletedEmployeeId);

  const onCloseModalClick = () => {
    dispatch(closeDeleteEmployeeModal());
  };

  const handleOnSubmitClick = async () => {
    setAdded && setAdded(false);
    await deleteEmployee(employeeId!);
    dispatch(closeDeleteEmployeeModal());
    dispatch(fetchEmployees());
  };

  return (
    <div className="dark-background">
      <div className="centered">
        <div className="modal confirm-modal">
          <div className="modal-header confirm-modal-header">
            <button onClick={onCloseModalClick}>
              <img src={closeIcon} alt="close-icon" className="close-icon" />
            </button>
          </div>
          <img
            src={deleteEmployeeIcon}
            alt="delete-employee-icon"
            className="delete-employee-icon"
          />
          <p className="label">{t('confirm_delete_user')}</p>
          <div className="modal-footer confirm-modal-footer">
            <button
              onClick={onCloseModalClick}
              className="button-white-green button-modal-cancel"
            >
              {t('no')}
            </button>
            <button onClick={handleOnSubmitClick} className="button-green">
              {t('yes')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteEmployeeModal;
