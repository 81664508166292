import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hooks/hooks';
import { selectIsUserLogged, logoutUser } from '../redux/authSlice';
import logo from '../static/images/logo.svg';
import openIcon from '../static/icons/open-arrow.svg';
import closeIcon from '../static/icons/close-arrow.svg';
import profileIcon from '../static/icons/profile.svg';
import logoutIcon from '../static/icons/logout.svg';

const NavBar = () => {
  const isUserLogged = useAppSelector(selectIsUserLogged);
  const { t, i18n } = useTranslation();

  const serialisedUser = isUserLogged! && localStorage.getItem('user');
  const user = serialisedUser && JSON.parse(serialisedUser!);
  const { name, surname, email } = user;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsOpen(false);
    setIsLanguageOpen(false);
  }, [navigate]);

  const onProfileClick = async () => {
    navigate('/user-profile');
  };
  const onSignOutClick = async () => {
    return await dispatch(logoutUser());
  };

  const onChangeLanguageClick = (lng: string) => {
    if (lng === i18n.language) return;
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <div className="navbar">
        <div className="logo">
          <Link to={'/'} className="link">
            <img src={logo} className="logo" alt="logo" />
          </Link>
        </div>
        {isUserLogged && (
          <>
            <div className="links">
              <Link to={'/notifications'} className="link">
                {t('push_notification')}
              </Link>
              <Link to={'/employees'} className="link">
                {t('employees')}
              </Link>
              <Link to={'/statistics/summary'} className="link">
                {t('statistics')}
              </Link>
              <div
                className="language"
                onClick={() => setIsLanguageOpen(!isLanguageOpen)}
              >
                <div>
                  <p className="name">{t('pick_language')}</p>
                </div>
                <button
                  className="button-toggle"
                  onClick={() => setIsLanguageOpen(!isLanguageOpen)}
                >
                  <img src={openIcon} className="arrow-icon" alt="arrow-icon" />
                </button>
                {isLanguageOpen && (
                  <div className="language-actions-list">
                    <button
                      className="button-toggle button-toggle-actions-list"
                      onClick={() => setIsLanguageOpen(!isLanguageOpen)}
                    >
                      <img
                        src={closeIcon}
                        className="arrow-icon"
                        alt="arrow-icon"
                      />
                    </button>
                    <button
                      className={
                        i18n.language === 'pl'
                          ? 'button-action-link active'
                          : 'button-action-link'
                      }
                      onClick={() => onChangeLanguageClick('pl')}
                    >
                      Polski
                    </button>
                    <button
                      className={
                        i18n.language === 'en'
                          ? 'button-action-link active'
                          : 'button-action-link'
                      }
                      onClick={() => onChangeLanguageClick('en')}
                    >
                      English
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="user" onClick={() => setIsOpen(!isOpen)}>
              <div className="navbar-avatar">
                <p className="navbar-avatar-inner">
                  {name && name.substring(0, 1).toUpperCase()}
                  {surname && surname.substring(0, 1).toUpperCase()}
                </p>
              </div>
              <div>
                <p className="name">
                  {name} {surname}
                </p>
                <p className="email">{email}</p>
              </div>
              <button
                className="button-toggle"
                onClick={() => setIsOpen(!isOpen)}
              >
                <img src={openIcon} className="arrow-icon" alt="arrow-icon" />
              </button>
            </div>
            {isOpen && (
              <div className="actions-list">
                <button
                  className="button-toggle button-toggle-actions-list"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <img
                    src={closeIcon}
                    className="arrow-icon"
                    alt="arrow-icon"
                  />
                </button>
                <button className="button-action-link" onClick={onProfileClick}>
                  <img
                    src={profileIcon}
                    className="profile-icon"
                    alt="profile-icon"
                  />
                  {t('profile')}
                </button>
                <button className="button-action-link" onClick={onSignOutClick}>
                  <img
                    src={logoutIcon}
                    className="logout-icon"
                    alt="logout-icon"
                  />
                  {t('logout')}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default NavBar;
