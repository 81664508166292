import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../hooks/hooks';
import { deleteInitiative } from '../api/initiatives';
import { closeDeleteInitiativeModal } from '../redux/modalsSlice';
import deleteEmployeeIcon from '../static/icons/delete-employee.svg';
import closeIcon from '../static/icons/close.svg';
import { resetInitialInitiative } from '../redux/initiativeCreationSlice';

const DeleteInitiativeModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { id } = useParams();

  const onCloseModalClick = () => {
    dispatch(closeDeleteInitiativeModal());
  };

  const handleOnSubmitClick = async () => {
    await deleteInitiative(id!);
    dispatch(closeDeleteInitiativeModal());
    dispatch(resetInitialInitiative());
    navigate('/dashboard');
  };

  return (
    <div className="dark-background">
      <div className="centered">
        <div className="modal confirm-modal">
          <div className="modal-header confirm-modal-header">
            <button onClick={onCloseModalClick}>
              <img src={closeIcon} alt="close-icon" className="close-icon" />
            </button>
          </div>
          <img
            src={deleteEmployeeIcon}
            alt="delete-employee-icon"
            className="delete-employee-icon"
          />
          <p className="label">{t('confirm_delete_initiative')}</p>
          <div className="modal-footer confirm-modal-footer">
            <button
              onClick={onCloseModalClick}
              className="button-white-green button-modal-cancel"
            >
              {t('no')}
            </button>
            <button onClick={handleOnSubmitClick} className="button-green">
              {t('yes')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteInitiativeModal;
