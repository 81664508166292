export interface CreateInitiative {
  dateFrom: string | Date;
  dateTo: string | Date;
  description: string;
  goal: string;
  photo: string;
  status: 'DRAFT' | 'ACTIVE' | 'HISTORICAL';
  title: string;
}

export interface Initiative extends CreateInitiative {
  id: string;
}

export enum InitiativeStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  HISTORICAL = 'HISTORICAL',
}
