import qs from 'qs';
import httpApi from './api';
import { ForgotPasswordData, ResetPasswordData } from '../types/forgotPassword';

export function forgotPasswordInitialize(data: ForgotPasswordData) {
  return httpApi.post(`/v1/hr/initializePasswordReset`, qs.stringify(data));
}

export function getForgotPasswordVerificationCode(verificationCode: string) {
  return httpApi.get(`/v1/hr/validatePasswordReset/${verificationCode}`);
}

export function finalizePasswordReset(
  data: ResetPasswordData,
  verificationCode: string
) {
  return httpApi.post(
    `/v1/hr/finalizePasswordReset/${verificationCode}`,
    qs.stringify(data)
  );
}
