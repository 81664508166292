import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InitialFlowStepper from './InitialFlowStepper';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../hooks/hooks';
import { enableInitialFlow, setCurrentStep } from '../redux/initialFlowSlice';
import addInitiativeIcon from '../static/icons/add-initiative.svg';

const CreateNewInitiativeInitialScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setCurrentStep(2));
  }, [dispatch]);

  const onCreateNewInitiativeClick = () => {
    dispatch(enableInitialFlow());
    return navigate('/initiatives/create-new');
  };

  return (
    <>
      <InitialFlowStepper />
      <div className="section">
        <div className="container">
          <div className="add-initial-initiative">
            <button
              className="button-add-initial-initiative"
              onClick={onCreateNewInitiativeClick}
            >
              <img
                src={addInitiativeIcon}
                alt="add-initiative-icon"
                className="add-initiative-icon"
              />{' '}
              {t('dashboard_add_initiative')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewInitiativeInitialScreen;
