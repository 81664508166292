import React, {
  useState,
  useEffect,
  useRef,
  MouseEvent,
  DragEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import EmployeeComponent from './Employee';
import DeleteEmployeeModal from './DeleteEmployeeModal';
import InitialFlowStepper from './InitialFlowStepper';
import InitialFlowFooter from './InitialFlowFooter';
import Paginate from './Paginate';

import { addEmployee } from '../api/employees';
import { useAppSelector, useAppDispatch } from '../hooks/hooks';
import { selectUsers, setImportedUsers } from '../redux/usersSlice';
import { fetchEmployees, selectEmployees } from '../redux/employeesSlice';
import {
  selectInitialFlowIsActive,
  setCurrentStep,
} from '../redux/initialFlowSlice';
import { selectDisplayDeleteEmployeeModal } from '../redux/modalsSlice';
import { Employee } from '../types/employee';
import addEmployeesAutoIcon from '../static/icons/add-employees-auto.svg';
import addEmployeesManualIcon from '../static/icons/add-employees-manual.svg';
import magnifierIcon from '../static/icons/magnifier.svg';
import clearIcon from '../static/icons/clear.svg';
import arrowIcon from '../static/icons/select-arrow.svg';
import { setError } from '../redux/errorSlice';

const AddUsersScreen = () => {
  const initialFlow = useAppSelector(selectInitialFlowIsActive);
  const employees = useAppSelector(selectEmployees);
  const { t } = useTranslation();

  const [surname, setSurname] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [surnameError, setSurnameError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const onSurnameChange = (value: string) => {
    setSurname(value);
    value.length > 2 ? setSurnameError(false) : setSurnameError(true);
  };

  const onNameChange = (value: string) => {
    setName(value);
    value.length > 2 ? setNameError(false) : setNameError(true);
  };

  const onEmailChange = (value: string) => {
    setEmail(value);
    isValidEmail(value) || !value ? setEmailError(false) : setEmailError(true);
  };

  const [added, setAdded] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentStep(1));
  }, [dispatch]);

  const employeesToAdd: Employee[] = useAppSelector(selectUsers);

  const [dragActive, setDragActive] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    setFile(e.dataTransfer.files[0]);
    e.dataTransfer.files[0] && handleOnSubmitFile(e.dataTransfer.files[0]);
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    e.target.files[0] && handleOnSubmitFile(e.target.files[0]);
  };

  const onUploadFileButtonClick = () => {
    inputRef.current!.click();
  };

  const csvFileToArray = (string: any) => {
    const lines = string.trim().split('\n');

    if (lines.length < 2) {
      return [];
    }

    const headers = lines[0].split(';');
    const dataRows = lines.slice(1);

    const array = dataRows
      .map((row: any) => {
        const values = row.split(';');

        if (values.length !== headers.length) {
          return null;
        }

        const obj = headers.reduce((object: any, header: any, index: any) => {
          const key = header.trim();
          const value = values[index].trim();
          object[key] = value;
          return object;
        }, {});

        return obj;
      })
      .filter(Boolean);
    dispatch(setImportedUsers(array));
  };

  const handleOnSubmitFile = async (file: any) => {
    const fileReader = new FileReader();
    fileReader.readAsText(file);
    await new Promise((resolve, reject) => {
      fileReader.onload = (event) => {
        resolve(csvFileToArray(event.target!.result));
      };
    });
    await buttonRef.current!.click();
  };

  const handleOnAddUserClick = async () => {
    const isEmptyName = name.trim().length === 0;
    const isEmptySurname = surname.trim().length === 0;

    if (!email) {
      setEmailError(true);
    }
    if (!surname || isEmptySurname) {
      setSurnameError(true);
    }
    if (!name || isEmptyName) {
      setNameError(true);
    }
    if (
      !emailError &&
      !surnameError &&
      !nameError &&
      !isEmptyName &&
      !isEmptySurname
    ) {
      if (isValidEmail(email) && surname.length > 2 && name.length > 2) {
        try {
          await addEmployee({ surname, name, email });
          setAdded(true);
          setSurname('');
          setName('');
          setEmail('');
          dispatch(fetchEmployees());
        } catch {
          //
        }
      }
    }
  };

  const handleOnAddUsersClick = async (e: MouseEvent) => {
    e.preventDefault();
    for (const employeeToAdd of employeesToAdd) {
      const { name, surname, email } = employeeToAdd;
      const data = { name, surname, email };
      try {
        await addEmployee(data);
        setAdded(true);
        dispatch(fetchEmployees());
      } catch (e) {
        dispatch(setError(`${t('error_wrong_email')} ${employeeToAdd?.email}`));
      }
    }
    dispatch(fetchEmployees());
  };

  const showModal = useAppSelector(selectDisplayDeleteEmployeeModal);

  const [searchInput, setSearchInput] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [elementsPerPage, setElementsPerPage] = useState<number>(5);

  const [filteredEmployees, setFilteredEmployees] =
    useState<Employee[]>(employees);
  const totalElements = employees.length;

  const indexOfLastElement = currentPage * elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;

  const options = [5, 10, 20, 50];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (option: any) => () => {
    setCurrentPage(1);
    setElementsPerPage(option);
    setIsOpen(false);
    setSelectedOption(option);
  };

  useEffect(() => {
    setFilteredEmployees(
      employees.slice(indexOfFirstElement, indexOfLastElement)
    );
  }, [employees, indexOfFirstElement, indexOfLastElement]);

  const handleChangeSearch = (e: any) => {
    if (e.target.value.length > 0) {
      setCurrentPage(1);
    }
    setSearchInput(e.target.value);
    if (e.target.value === '') {
      return setFilteredEmployees(
        employees.slice(indexOfFirstElement, indexOfLastElement)
      );
    }
    const filteredElements = employees.filter(
      (employee: Employee) =>
        `${employee.name.toLowerCase()} ${employee.surname.toLowerCase()}`.includes(
          searchInput.toLowerCase()
        ) || employee.email.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredEmployees(filteredElements);
  };

  const onClearIconClick = () => {
    setSearchInput('');
    setFilteredEmployees(
      employees.slice(indexOfFirstElement, indexOfLastElement)
    );
  };

  return (
    <>
      {initialFlow && <InitialFlowStepper />}
      <div className="section">
        <div className="container">
          <p className="section-title">{t('add_employee_movello')}</p>
          <p className="label">{t('add_employee_label')}</p>
          <div className="add-employees-form">
            <div className="title-with-icon">
              <img src={addEmployeesAutoIcon} alt="add-employees-auto-icon" />
              <p className="label">{t('automatically')}</p>
            </div>
            <div>
              <form
                className="form-file-upload"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  ref={inputRef}
                  type="file"
                  className="input-file-upload"
                  multiple={true}
                  onChange={handleChange}
                  accept={'.csv'}
                />
                <label
                  htmlFor="input-file-upload"
                  className={
                    dragActive
                      ? 'drag-active label-file-upload'
                      : 'label-file-upload'
                  }
                >
                  <p>{(file && file.name) || t('drag_emplyee')}</p>
                </label>
                {dragActive && (
                  <div
                    className="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
                <button
                  className="hidden"
                  ref={buttonRef}
                  onClick={handleOnAddUsersClick}
                >
                  {t('add_employees')}
                </button>
                <button
                  className="button-green"
                  onClick={onUploadFileButtonClick}
                >
                  {t('load_csv')}
                </button>
              </form>
              <div className="upload-file-template">
                <p>{t('template_csv')}</p>
                <a
                  href={require('../static/files/employees-template.csv')}
                  download="employees-template"
                >
                  <button className="button-white-green">
                    {t('template_csv_download')}
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="add-employees-form">
            <div className="title-with-icon">
              <img
                src={addEmployeesManualIcon}
                alt="add-employees-manual-icon"
              />
              <p className="label">{t('manually')}</p>
            </div>
            <div className="add-user-form">
              <div className="form-group">
                <label
                  htmlFor="surname"
                  className={surnameError ? 'label-error' : ''}
                >
                  {surnameError ? t('error_wrong_surname') : t('surname')}
                </label>
                <input
                  type="text"
                  className={
                    surnameError ? 'form-input input-error' : 'form-input'
                  }
                  onChange={(e) => onSurnameChange(e.target.value)}
                  value={surname}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="name"
                  className={nameError ? 'label-error' : ''}
                >
                  {nameError ? t('error_wrong_name') : t('name')}
                </label>
                <input
                  type="text"
                  className={
                    nameError ? 'form-input input-error' : 'form-input'
                  }
                  onChange={(e) => onNameChange(e.target.value)}
                  value={name}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="email"
                  className={emailError ? 'label-error' : ''}
                >
                  {emailError ? t('error_wrong_email') : t('email')}
                </label>
                <input
                  type="email"
                  className={
                    emailError ? 'form-input input-error' : 'form-input'
                  }
                  onChange={(e) => onEmailChange(e.target.value)}
                  value={email}
                />
              </div>
              <button className="button-green" onClick={handleOnAddUserClick}>
                {t('add_user')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          {added && <p className="section-title">{t('add_user_success')}</p>}
          <div className="employee-list-actions">
            <div className="employee-input-search">
              <label>{t('search_person')}</label>
              <img
                src={magnifierIcon}
                alt="magnifier-icon"
                className="magnifier-icon"
              />
              <input
                type="text"
                value={searchInput}
                onChange={handleChangeSearch}
                className="input-search"
              />
              <button className="button-clear-input" onClick={onClearIconClick}>
                <img
                  src={clearIcon}
                  alt="clear-icon"
                  className={
                    searchInput !== '' ? 'clear-icon' : 'clear-icon hidden'
                  }
                />
              </button>
            </div>
            <div className="employee-select-count">
              <label>{t('show')}</label>
              <div className="select-employees-number" onClick={toggling}>
                <div className="dropdown-header">
                  <img
                    src={arrowIcon}
                    alt="arrow-icon"
                    className="arrow-icon"
                  />
                  {selectedOption}
                </div>
                {isOpen && (
                  <div className="dropdown-list-container">
                    <ul className="dropdown-list">
                      {options.map((option, i) => (
                        <li
                          className="list-item"
                          onClick={onOptionClicked(option)}
                          key={i}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <p className="section-title">{t('participants')}</p>
          {filteredEmployees &&
            filteredEmployees.map((employee: Employee) => (
              <EmployeeComponent employee={employee} key={employee.id} />
            ))}
          <Paginate
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalElements={totalElements}
            postPerPage={elementsPerPage}
            color={'green'}
          />
        </div>
      </div>
      {showModal && <DeleteEmployeeModal setAdded={setAdded} />}
      {initialFlow && (
        <InitialFlowFooter
          forwardPath={'/initiatives/create-new'}
          backPath={'/'}
        />
      )}
    </>
  );
};

export default AddUsersScreen;
