import React from 'react';
import DatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerComponent = ({ value, onChange, ...props }: any) => {
  const { t } = useTranslation();
  const today = new Date();
  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    t('time_january'),
    t('time_february'),
    t('time_march'),
    t('time_april'),
    t('time_may'),
    t('time_june'),
    t('time_july'),
    t('time_august'),
    t('time_september'),
    t('time_october'),
    t('time_november'),
    t('time_december'),
  ];
  const days = [
    t('time_sunday'),
    t('time_monday'),
    t('time_tuesday'),
    t('time_wednesday'),
    t('time_thursday'),
    t('time_friday'),
    t('time_saturday'),
  ];
  const locale = {
    localize: {
      day: (n: any) => days[n],
      month: (n: any) => months[n],
    },
    formatLong: {
      date: () => 'dd-MM-yyyy',
    },
  };
  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button
            onClick={(e) => {
              e.preventDefault();
              decreaseMonth();
            }}
            disabled={prevMonthButtonDisabled}
          >
            {'<'}
          </button>
          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }: any) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button
            onClick={(e) => {
              e.preventDefault();
              increaseMonth();
            }}
            disabled={nextMonthButtonDisabled}
          >
            {'>'}
          </button>
        </div>
      )}
      selected={value}
      onChange={(e: any) => onChange(e.toISOString())}
      className="form-input"
      showPopperArrow={false}
      calendarStartDay={1}
      dateFormat="dd-MM-yyyy"
      locale={locale}
      minDate={today}
      {...props}
    />
  );
};

export default DatePickerComponent;
