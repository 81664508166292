import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import { getEmployees } from '../api/employees';
import { Employee } from '../types/employee';

export interface EmployeesState {
  data: Employee[];
  status: 'idle' | 'loading' | 'failed';
  deletedEmployeeId: string | number;
}

const serializedEmployees = localStorage.getItem('employees');

const employeesData = JSON.parse(serializedEmployees!);

const initialState: EmployeesState = {
  data: employeesData || [],
  status: 'idle',
  deletedEmployeeId: '',
};

export const fetchEmployees = createAsyncThunk(
  'employees/fetchEmployees',
  async () => {
    const response = await getEmployees();
    localStorage.setItem('employees', JSON.stringify(response));
    return response;
  }
);

export const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    fetchEmployees: (state) => {
      state.data = [];
    },
    setDeletedEmployeeId: (state, action: PayloadAction<string | number>) => {
      state.deletedEmployeeId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployees.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload;
      })
      .addCase(fetchEmployees.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setDeletedEmployeeId } = employeesSlice.actions;

export const selectEmployees = (state: RootState) => state.employees.data;
export const selectDeletedEmployeeId = (state: RootState) =>
  state.employees.deletedEmployeeId;

export default employeesSlice.reducer;
