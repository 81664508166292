import qs from 'qs';
import httpApi from './api';
import { Employee } from '../types/employee';

export const getEmployees = async (): Promise<Employee[]> => {
  return await httpApi
    .get('/v1/hr/employees')
    .then((res) =>
      res.data.data.sort((a: Employee, b: Employee) =>
        a.name > b.name ? 1 : -1
      )
    );
};

export const addEmployee = async (data: Employee): Promise<Employee> => {
  return await httpApi
    .post('/v1/hr/employees', qs.stringify(data))
    .then((res) => res.data.data);
};

export const deleteEmployee = async (id: string | number): Promise<void> => {
  return await httpApi.delete(`/v1/hr/employees/${id}`);
};

export const getEmployeesInInitiative = async (
  id: string | number
): Promise<Employee[]> => {
  return await httpApi
    .get(`/v1/hr/initiatives/${id}/employees`)
    .then((res) => res.data.data);
};
