import qs from 'qs';
import httpApi from './api';
import { CreateInitiative, Initiative } from '../types/initiatives';

export const getInitiatives = async (): Promise<Initiative[]> => {
  return await httpApi.get('/v1/hr/initiatives').then((res) => res.data.data);
};

export const createInitiative = async (
  data: CreateInitiative
): Promise<Initiative> => {
  return await httpApi
    .post('/v1/hr/initiatives', qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((res) => res.data.data);
};

export const editInitiative = async (
  id: string,
  data: Initiative
): Promise<void> => {
  return await httpApi.put(`/v1/hr/initiatives/${id}`, qs.stringify(data));
};

export const launchInitiative = async (id: string): Promise<void> => {
  return await httpApi.post(`/v1/hr/initiatives/${id}/launch`);
};

export const deleteInitiative = async (id: string): Promise<void> => {
  return await httpApi.delete(`/v1/hr/initiatives/${id}`);
};
