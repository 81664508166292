import axios from 'axios';
import { API_PATH } from '../config';
import { store } from '../redux/store';
import { setError, resetError } from '../redux/errorSlice';
import '../i18n';
import { t } from 'i18next';

const errorMessages: { [index: string]: string } = {
  bZ9OmzLIV3gkspu3WeVsa3: t('error_wrong_initiative_title'),
  huE0yaljXsMkmU7irocJsa: t('error_wrong_initiative_description'),
  wS0j3BU9h3mMcyLIew8o2g: t('error_wrong_initiative_goal'),
  QjMusdDg74vU4asPMJg87F: t('error_wrong_image_link'),
  jLjdHuo4THbxvwdn2PpMrq: t('error_wrong_date'),
  pnIvzps53qYg1em5aueewF: t('error_wrong_edition_date'),
  OSfsX7C66GtcfTwd1E8Isa: t('error_wrong_edition_incomplete'),
  M9XSajpeJlaxHUeCyJ7azr: t('error_wrong_edition_initiative'),
  TIJNRi77GixtdQPR60Hqas: t('error_wrong_edition_start_incomplete'),
  u3ug5KX0YfQ8SD3WeB8baa: t('error_wrong_edition_start_invalid_date'),
  '9A6IxKW607QtFeKbUQindd': t('error_wrong_edition_other_pending'),
  nT3LqOiXLkei9ueewuSrUw: t('error_wrong_edition_pending'),
  OM5TGp91nNehWtLDno0dwt: t('error_wrong_name'),
  '3yZKJbCIt1pGC7icrlwe0r': t('error_wrong_surname'),
  eMb9n5n5hbcI2qQO2lFmef: t('error_wrong_email'),
  QVew00nmhCc7R0ZpLz8BWb: t('error_email_already_added'),
  vAefYrgGdnzCtbnNDmB36T: t('error_invalid_team_name'),
  o5IzEwmM2GFewko7LgF2uh: t('error_no_users'),
  kgNkh1rdcUgtk6YGwewFxD: t('error_invalid_user_data'),
  '4dme4L5O7fAwBBvKBT6efT': t('error_user_not_added'),
  iweZ2Dh8lXeSG6djeRxYxs: t('error_user_in_another_group'),
  k9fkmO28RmewIBTB1gKvvo: t('error_edititng_team'),
  r8VAFeLGGgcefB1HYKAcSq: t('error_invalid_notification_title'),
  '8EMm4u9VvpJxQreFVLZ8Fe': t('error_invalid_notification_content'),
  '0mGUb93zoGAqmodW0hdKew': t('error_too_big_file'),
  N6h1EfTewnKBFQDjZofxLF: t('error_invalid_format_file'),
  TD5JF7uVHlFinJvyrevphw: t('error_invalid_type_file'),
  DSoznrbwemAdt9jPheewOR: t('error_invalid_image_dimensions'),
  Q2NPURb2k4y2fxzeFpew81: t('error_invalid_start_date'),
  RXIdxcf5FODLTJCwhpewhB: t('error_invalid_end_date'),
  wRKpYCifzq4XpidPX0ewOQ: t('error_invalid_page_number'),
  xjFSDZmvk7XB9VDky2ewXX: t('error_invalid_page_size'),
};

const httpApi = axios.create({
  baseURL: API_PATH,
  headers: {},
});

httpApi.interceptors.request.use((request) => {
  const authToken = localStorage.getItem('authToken');
  if (authToken) {
    // @ts-ignore: Object is possibly 'null'.
    request.headers.common['x-auth'] = authToken;
  }
  return request;
});

httpApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { dispatch } = store;
    const { status } = error.response;
    const { errorCode } = error.response.data;
    const { responseURL } = error.request;

    const message =
      errorMessages[errorCode] ||
      (status === 401 &&
        responseURL.includes('/login') &&
        'Nieprawidłowy login lub hasło') ||
      (status === 401 && 'Brak autoryzacji') ||
      error.message;

    status === 401 &&
      responseURL.includes('/login') &&
      dispatch(setError(message));

    if (status === 401) {
      localStorage.removeItem('authToken');
      if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
    }
    dispatch(setError(message));
    setTimeout(() => dispatch(resetError()), 5000);
    return error;
  }
);

export default httpApi;
