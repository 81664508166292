import React from 'react';
import { useAppSelector } from '../hooks/hooks';
import { selectError } from '../redux/errorSlice';

const ErrorComponent = () => {
  const error = useAppSelector(selectError);
  return error && error !== 'Brak autoryzacji' ? (
    <div className="error">{error}</div>
  ) : null;
};

export default ErrorComponent;
