import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './NavBar';
import Error from './Error';
import Success from './Success';
import DashboardScreen from './DashboardScreen';
import EditInitiativeScreen from './EditInitiativeScreen';
import CreateNewInitiativeScreen from './CreateNewInitiativeScreen';
import CreateNewInitiativeConfirmScreen from './CreateNewInitiativeConfirmScreen';
import CreateNewInitiativeInitialScreen from './CreateNewInitiativeInitialScreen';
import AddGroupsScreen from './AddGroupsScreen';
import AddAllToOneGroupScreen from './AddAllToOneGroupScreen';
import AddShuffledGroupsScreen from './AddShuffledGroupsScreen';
import AddManualGroupsScreen from './AddManualGroupsScreen';
import LoginScreen from './LoginScreen';
import ForgotPasswordScreen from './ForgotPasswordScreen';
import ResetPasswordScreen from './ResetPasswordScreen';
import UserProfileScreen from './UserProfileScreen';
import AddEmployeesScreen from './AddEmployeesScreen';
import AddEmployeesInitialScreen from './AddEmployeesInitialScreen';
import EmployeesScreen from './EmployeesScreen';
import NotificationsScreen from './NotificationsScreen';
import StatisticsSummaryScreen from './StatisticsSummaryScreen';
import StatisticsInitiativeScreen from './StatisticsInitiativeScreen';
import StatisticsGroupScreen from './StatisticsGroupScreen';
import ErrorScreen from './ErrorScreen';
import RequireAuth from './RequireAuth';

const AppContainer = () => {
  return (
    <>
      <Router>
        <>
          <NavBar />
          <Error />
          <Success />
        </>
        <Routes>
          <Route path="/" element={<DashboardScreen />} />
          <Route path="/dashboard" element={<DashboardScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
          <Route path="/reset-password" element={<ResetPasswordScreen />} />
          <Route element={<RequireAuth />}>
            <Route
              path="/initiatives/create-new"
              element={<CreateNewInitiativeScreen />}
            />
            <Route
              path="/initiatives/create-new/initial"
              element={<CreateNewInitiativeInitialScreen />}
            />
            <Route
              path="/initiatives/create-new/groups/add"
              element={<AddGroupsScreen />}
            />
            <Route
              path="/initiatives/create-new/groups/add-all"
              element={<AddAllToOneGroupScreen />}
            />
            <Route
              path="/initiatives/create-new/groups/add-shuffled"
              element={<AddShuffledGroupsScreen />}
            />
            <Route
              path="/initiatives/create-new/groups/add-manual"
              element={<AddManualGroupsScreen />}
            />
            <Route
              path="/initiatives/create-new/confirm"
              element={<CreateNewInitiativeConfirmScreen />}
            />
            <Route
              path="/initiatives/edit/:id"
              element={<EditInitiativeScreen />}
            />
            <Route path="/user-profile" element={<UserProfileScreen />} />
            <Route path="/employees" element={<EmployeesScreen />} />
            <Route path="/employees/add" element={<AddEmployeesScreen />} />
            <Route
              path="/employees/add/initial"
              element={<AddEmployeesInitialScreen />}
            />
            <Route
              path="/statistics/summary"
              element={<StatisticsSummaryScreen />}
            />
            <Route
              path="/statistics/initiative/:id"
              element={<StatisticsInitiativeScreen />}
            />
            <Route
              path="/statistics/group/:id"
              element={<StatisticsGroupScreen />}
            />
            <Route path="/notifications" element={<NotificationsScreen />} />
            <Route
              path="/notifications/initiatives/:initiativeId"
              element={<NotificationsScreen />}
            />
            <Route
              path="/notifications/initiatives/:initiativeId/groups/:groupId"
              element={<NotificationsScreen />}
            />
            <Route
              path="/notifications/users/:userId"
              element={<NotificationsScreen />}
            />
          </Route>
          <Route path="*" element={<ErrorScreen />} />
        </Routes>
      </Router>
    </>
  );
};

export default AppContainer;
