import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Paginate from './Paginate';
import {
  getStatisticsSummary,
  getActiveUsers,
  getTotalUsers,
} from '../api/statistics';
import { getEmployeesRanking } from '../api/ranking';
import { StatisticsSummaryData, StatisticsData } from '../types/statistics';
import { RankingData } from '../types/ranking';
import { dateTo, dateFrom, formatISODate } from '../helpers/dates';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import calendarIcon from '../static/icons/calendar.svg';
import DatePicker from './DatePicker';

const StatisticsSummaryScreen = () => {
  const [statistics, setStatisticsData] = useState<StatisticsSummaryData>();
  const [ranking, setRankingData] = useState<RankingData[]>();
  const [activeUsers, setActiveUsersData] = useState<StatisticsData[]>([]);
  const [totalUsers, setTotalUsersData] = useState<StatisticsData[]>([]);
  const [from, setDateFrom] = useState<string>(dateFrom);
  const [to, setDateTo] = useState<string>(dateTo);
  const [valueFrom, setValueFrom] = useState<string>(dateFrom);
  const [valueTo, setValueTo] = useState<string>(dateTo);
  const { t } = useTranslation();

  const handleDateFromOnChange = (value: string) => {
    setDateFrom(formatISODate(value));
    setValueFrom(value);
  };

  const handleDateToOnChange = (value: string) => {
    setDateTo(formatISODate(value));
    setValueTo(value);
  };

  useEffect(() => {
    (async () => {
      const statisticsData: StatisticsSummaryData =
        await getStatisticsSummary();
      setStatisticsData(statisticsData);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const rankingData: RankingData[] = await getEmployeesRanking({
        from,
        to,
      });
      const mappedData = rankingData.map((el, index) => {
        return { ...el, index: index + 1 };
      });
      setRankingData(mappedData);
    })();
  }, [from, to]);

  useEffect(() => {
    (async () => {
      const activeUsersData: StatisticsData[] = await getActiveUsers({
        from,
        to,
      });
      setActiveUsersData(activeUsersData);
    })();
  }, [from, to]);

  useEffect(() => {
    (async () => {
      const totalUsersData: StatisticsData[] = await getTotalUsers({
        from,
        to,
      });
      setTotalUsersData(totalUsersData);
    })();
  }, [from, to]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          boxHeight: 0,
          boxWidth: 20,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#ffffff',
        borderColor: 'rgba(0, 0, 0, 0.5)',
        borderWidth: 0.5,
        cornerRadius: 6,
        titleColor: '#707070',
        titleFont: { weight: 'normal' },
        displayColors: false,
        caretSize: 10,
        bodyFont: { size: 14, weight: 'bold' },
        padding: {
          left: 10,
          right: 10,
          top: 10,
          bottom: 10,
        },
        callbacks: {
          label: (tooltipItem: any) => {
            return `${tooltipItem.dataset.label.split(' ')[0]}: ${
              tooltipItem.formattedValue
            }`;
          },
          labelTextColor: (tooltipItem: any) => {
            return tooltipItem.dataset.borderColor;
          },
        },
      },
    },
    scales: {
      xAxis: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        title: {
          display: true,
          text: t('chart_days'),
          align: 'end',
        },
      },
      yAxis: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        title: {
          display: true,
          text: t('chart_users_amount'),
          align: 'end',
        },
      },
    },
    interaction: { intersect: false },
    elements: {
      point: {
        borderWidth: 0,
        radius: 0,
      },
    },
  };

  const data = {
    labels: activeUsers?.map((el) => el.title),
    datasets: [
      {
        label: t('chart_active_users'),
        data: activeUsers?.map((el) => el.value),
        borderColor: '#01C158',
        fill: false,
        borderWidth: 3,
      },
      {
        label: t('chart_users_all'),
        data: totalUsers?.map((el) => el.value),
        borderColor: '#DADADA',
        fill: false,
        borderWidth: 3,
      },
    ],
  };

  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalElements = ranking && ranking.length;
  const elementsPerPage = 6;

  const [filteredRankingElements, setFilteredRankingElements] = useState<
    RankingData[]
  >(ranking!);

  const indexOfLastElement = currentPage * elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;

  useEffect(() => {
    setFilteredRankingElements(
      ranking! && ranking.slice(indexOfFirstElement, indexOfLastElement)
    );
  }, [indexOfFirstElement, indexOfLastElement, ranking]);

  return (
    <div className="section">
      <div className="container">
        <p className="section-title">{t('company_statistics')}</p>
        <div className="statistics-container statistics-data">
          <p>{t('company_statistics_steps')}</p>
          <p className="number">
            {statistics &&
              statistics.steps.toLocaleString().replaceAll(',', ' ')}{' '}
            <span className="km">
              (
              {statistics &&
                Math.round((+statistics!.steps * 0.7) / 1000)
                  .toLocaleString()
                  .replaceAll(',', ' ')}{' '}
              km)
            </span>
          </p>
        </div>
        <div className="statistics-container statistics-data">
          <p>{t('company_statistics_employees')} </p>
          <p className="number"> {statistics && statistics.users}</p>
        </div>
        <div className="statistics-container">
          <div className="form-group-row">
            <p className="label">{t('initiative_time')}</p>
            <div>
              <DatePicker
                className="form-input"
                selected={new Date(valueFrom)}
                onChange={handleDateFromOnChange}
                value={new Date(valueFrom)}
              />
              <img
                src={calendarIcon}
                alt="calendar-icon"
                className="calendar-icon-statistics"
              />
            </div>
            <div>
              <DatePicker
                className="form-input"
                selected={new Date(valueTo)}
                onChange={handleDateToOnChange}
                value={new Date(valueTo)}
              />
              <img
                src={calendarIcon}
                alt="calendar-icon"
                className="calendar-icon-statistics"
              />
            </div>
          </div>
          <div className="statistics-elements">
            <div className="chart">
              <div className="chart-row">
                <p className="chart-title">{t('company_active_employees')}</p>
              </div>
              {/* 
              // @ts-ignore */}
              <Line options={options} data={data} />
            </div>
            <div className="ranking">
              <div className="ranking-row">
                <p className="title">{t('initiative_ranking')}</p>
                <p className="steps">{t('steps')}</p>
              </div>
              <ol className="ranking-list">
                {filteredRankingElements &&
                  filteredRankingElements.map((el, i) => {
                    return (
                      <li key={i}>
                        <div
                          className={
                            (el.index! === 1 && 'avatar-first-green') ||
                            (el.index! === 2 && 'avatar-second-green') ||
                            (el.index! === 3 && 'avatar-third-green') ||
                            'avatar'
                          }
                        >
                          <p
                            className={
                              ((el.index! === 1 ||
                                el.index! === 2 ||
                                el.index! === 3) &&
                                'avatar-inner-top') ||
                              'avata-inner'
                            }
                          >
                            {el.index}
                          </p>
                        </div>
                        <p className={el.index! <= 3 ? 'top-name' : 'name'}>
                          {el.title}
                        </p>
                        <p className="value">
                          {el.value.toLocaleString().replaceAll(',', ' ')}
                        </p>
                      </li>
                    );
                  })}
              </ol>
              <Paginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalElements={totalElements!}
                postPerPage={elementsPerPage}
                color={'green'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsSummaryScreen;
